<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("DIC_VITRINE") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-ajout float-right"
        v-b-modal.dictionnaireModal
        @click="openModal('dictionnaireModal')"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
      <dictionnaireModal />
    </div>
    <div class="content">
      <div class="content-header">
        <div class="input-group">
          <b-form-input
            v-model="search"
            @keyup="debounceInput"
            :placeholder="$t('TRADUCTION_SEARCH')"
          ></b-form-input>
          <font-awesome-icon icon="search" class="icon-search" v-if="!search" />
        </div>
        <b-col md="4">
          <b-form-group :label="$t('LANGUAGES')">
            <multiselect
              v-model="lang"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getActiveLangue"
              label="lib"
              track-by="id"
              @input="handleChangLang"
            >
              <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </div>

      <p class="table-count">
        {{ getTotalMultiLangue }} {{ $t("DIC_VITRINE") }}
      </p>
      <ModelImportdictionnaire />
      <div class="table-rapport-style w-100 vld-parent">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 5px 0px 0px 0px">
                {{ $t("KEY") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("WORDING") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getmultiLangues"
              :key="i"
              :class="{ no_default: data.is_default }"
              @click="handleUpdate(data)"
            >
              <td aria-colindex="1" role="cell">
                {{ data.key }}
              </td>
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>

              <td aria-colindex="3" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfigdictionnaire"
        ref="ModelConfigdictionnaire"
        title="Configuration"
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("DIC_VITRINE") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form
          @submit.prevent="dictionnaireModalNew"
          v-if="dictionnaireToConfig"
        >
          <div class="center">
            <b-form-group :label="$t('WORDING')" label-for="name">
              <b-form-input
                id="name"
                v-model="dictionnaireToConfig.lib"
              ></b-form-input>
              <div
                v-if="v$.dictionnaireToConfig.lib.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('KEY')" label-for="key">
              <b-form-input
                id="key"
                v-model="dictionnaireToConfig.key"
              ></b-form-input>
              <div v-if="erreurlist.key" class="error-message">
                <ul v-if="Array.isArray(erreurlist.key)">
                  <span v-for="(e, index) in erreurlist.key" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.key }}</span>
              </div>
              <div
                v-if="v$.dictionnaireToConfig.key.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('LANGUAGES') + '*'">
              <multiselect
                v-model="dictionnaireToConfig.lang"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getActiveLangue"
                label="lib"
                track-by="id"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.lang" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lang)">
                  <span v-for="(e, index) in erreurlist.lang" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lang }}</span>
              </div>
              <div
                v-if="v$.dictionnaireToConfig.lang.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
          </div>
        </form>
        <template #modal-footer>
          <div class="double">
            <b-alert variant="warning" show v-if="errorU">
              {{ errorU }}
            </b-alert>
            <b-button variant="danger" @click="resetModal">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button @click="dictionnaireModalNew" variant="success">
              <div class="block-spinner">
                {{ $t("EDIT") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getLoadingMultiLangue"
        :elemToDelete="dictionnaireToDelete"
        :elemDelete="delete_MultiLangue"
        @updateList="getModels($event)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalMultiLangue"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalMultiLangue"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingMultiLangue"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import axios from "axios";
import _ from "lodash";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import dictionnaireModal from "@/components/Ajoutmodel/dictionnaireModal.vue";

export default {
  components: {
    deleteModalVue,
    dictionnaireModal,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      search: "",
      dictionnaireToConfig: null,
      dictionnaireToDelete: null,
      page: 1,
      perPage: 10,
      lang: null,
      isLoading: false,
      fullPage: true,
      newDIC: {
        lib: null,
        key: null,
        lang: null,
      },
      erreurlist: {
        lang: null,
        key: null,
      },
      selectedlangs: [],
      oldrefrence: null,
      loading: false,
      file: {},
      errorD: false,
      errorS: false,
      errorU: false,
      apiUrl: domains.api,
    };
  },
  validations() {
    return {
      newDIC: {
        lib: { required },
        key: { required },
        lang: { required },
      },
      dictionnaireToConfig: {
        lib: { required },
        key: { required },
        lang: { required },
      },
      $validationGroups: {
        updateGroup: ["dictionnaireToConfig"],
      },
    };
  },
  methods: {
    ...mapActions([
      "allLangue",
      "edit_MultiLangue",
      "allMultiLangue",
      "store_MultiLangue",
      "delete_MultiLangue",
      "import_MultiLangue",
      "allActiveLangues",
    ]),
    openModal(type) {
      
      if (type === "dictionnaireModal") {
        this.$root.$emit("bv::show::modal", "dictionnaireModal");
      }
    },
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;

      this.allMultiLangue({
        lang: this.lang.iso_code,
        search: this.search,
      });
    }, 1000),
    handleChangLang() {
      this.allMultiLangue({
        lang: this.lang.iso_code,
        search: this.search,
      });
    },
    getModels() {
      this.allMultiLangue({
        page: this.page,
        per_page: this.perPage,
        lang: this.lang.iso_code,
      });

      this.allActiveLangues({
        page: 1,
        per_page: 100,
      });
    },
    resetModal() {
      this.newDIC = {
        lib: null,
        key: null,
        lang: null,
      };
      this.erreurlist = {
        lib: null,
        key: null,
        lang: null,
      };
      this.errorS = null;
      this.errorU = null;
      this.v$.$reset();
      // this.$refs["dictionnaireModal"].hide();
      this.$refs["ModelConfigdictionnaire"].hide();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleUpdate(LANG) {
      this.dictionnaireToConfig = LANG;

      this.getActiveLangue.map((item) => {
        if (item.iso_code == this.dictionnaireToConfig.lang) {
          this.dictionnaireToConfig.lang = item;
        }
      });

      this.$refs["ModelConfigdictionnaire"].show();
    },
    handleDelete(LANG) {
      this.dictionnaireToDelete = { ...LANG };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async dictionnaireModalNew() {
      const isFormCorrect = await this.v$.$validationGroups.updateGroup.$error;
      if (isFormCorrect) return;
      this.erreurlist = {
        lang: null,
        key: null,
      };
      this.errorU = null;
      await this.edit_MultiLangue({
        lib: this.dictionnaireToConfig.lib,
        key: this.dictionnaireToConfig.key,
        lang: this.dictionnaireToConfig.lang.iso_code,
        id: this.dictionnaireToConfig.id,
      })
        .then(() => {
          this.hideModal("ModelConfigdictionnaire");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    async addLANG() {
      await this.v$.$validate();
      const isFormCorrect = await this.v$.newDIC.$error;
      if (isFormCorrect) return;
      this.erreurlist = {
        lang: null,
        key: null,
      };
      this.errorS = null;
      await this.store_MultiLangue(this.newDIC)
        .then(() => {
          this.errorS = null;
          this.hideModal("dictionnaireModal");
          this.resetModal();
          this.setup(true);
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },

    dictionnaireExport() {
      this.loading = true;
      axios({
        url: `${this.apiUrl}/dictionnaire/export?lang=${this.lang.iso_code}`,
        method: "GET",
        headers: { Authorization: `Bearer ${this.getToken}` },
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "dictionnaire.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loading = false;
      });
    },

    pagination(paginate) {
      this.page = paginate;
      this.allMultiLangue({
        page: this.page,
        per_page: this.perPage,
        lang: this.lang.iso_code,
        search: this.search,
      });
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.getModels();
    },
  },

  mounted() {
    this.lang = this.getSelectedLang;
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getmultiLangues",
      "getTabs",
      "getSelectedLang",
      "getToken",
      "getTotalMultiLangue",
      "getLoadingMultiLangue",
      "getActiveLangue",
      "getActiveLangue",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  text-align: center !important;

  .icon_trie {
    color: #28367a;
  }
}
.prodimg {
  max-width: 85px;
  max-height: 72px;
}

.content {
  .content-header {
    @media only screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }
    .btn {
      @media only screen and (max-width: 900px) {
        width: 40%;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
      }
    }
    .input-group {
      @media only screen and (max-width: 900px) {
        width: 90%;
      }

      width: 40%;
      height: 48px;
      position: relative;

      input {
        background-color: #f5f6f8;
        padding: 8px 48px;
        height: 100%;
      }

      .icon-search {
        @media only screen and (max-width: 900px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 15px;
        color: #515151;
      }
      .icon-balance {
        display: none;
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 15px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 14px;
      }
    }

    .affichage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 2px solid #00000019;

      .font_icon {
        cursor: pointer;
        margin: 0 4px;
        border: 2px solid #000;
        padding: 2px 8px;
        border-radius: 50%;

        .icon_down,
        .icon_up {
          @media only screen and (max-width: 900px) {
            font-size: 14px;
          }
          font-size: 16px;
          font-weight: 500;
        }

        .icon_down {
          color: #28367a;
        }

        .icon_up {
          color: #e4261b;
        }
      }

      .up {
        border-color: #e4261b;
      }

      .down {
        border-color: #28367a;
      }
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;

  .messageError {
    position: absolute;
    top: 0;
    color: #e4261b;
    margin: 0;
    font-size: 12px;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}

.detailClient {
  background-color: #fff;

  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;

    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
