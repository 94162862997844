<template>
    <div class="mx-5">
      <div class="row my-2">
        <!-- Client Section -->
        <div class="col-6">
          <div class="flex-1">
            <div>{{ $t("CLIENT") }}</div>
            <div class=" row">
              <div class="col-10">
                <multiselect
                  v-model="selectedClient"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllclients"
                  label="nom"
                  track-by="id"
                  :placeholder="$t('Select_agent')"
                  class="form-input custom-placeholder custom-input"
                  style="width: 100%"
                  @input="handleInput('selectedClient', selectedClient)"
                />
              </div>
              <div class="col-1">
                <button
                  class="py-0 newstore rounded"
                  @click="openModal('client')"
                  id="buttonclient-with-popover"
                >
                  +
                </button>
                <b-popover
                  target="buttonclient-with-popover"
                  placement="top"
                  triggers="hover focus"
                >
                  Vous pouvez ajouter un nouveau client
                </b-popover>
                <clientmodale @updateList="setup()" />
              </div>
            </div>
          </div>
        </div>
  
        <!-- User Section -->
        <div class="col-6">
          <div class="flex-1">
            <div>{{ $t("USER") }}</div>
            <div class="row">
              <div class="col-10">
                <multiselect
                  v-model="selectedUser"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllUsers"
                  label="nom"
                  track-by="id"
                  :placeholder="$t('Select_agent')"
                  class="form-input custom-placeholder custom-input"
                  style="width: 100%"
                  @input="handleInput('selectedUser', selectedUser)"
                />
              </div>
              <div class="col-1">
                <button
                  class="py-0 newstore rounded"
                  @click="openModal('user')"
                  id="buttonuser-with-popover"
                >
                  +
                </button>
                <b-popover
                  target="buttonuser-with-popover"
                  placement="top"
                  triggers="hover focus"
                >
                  Vous pouvez ajouter un nouvel utilisateur
                </b-popover>
                <usermodale @updateList="setup()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import clientmodale from "@/components/Ajoutmodel/clientmodale.vue";
  import { mapGetters, mapActions } from "vuex";
import usermodale from "../Ajoutmodel/usermodale.vue";
  export default {
    components: {
        usermodale,
        clientmodale
    },
    data() {
      return {
        paiement_state: [],
        selectedUser:null,
      optionspaiement: [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ],
      selectedMoyensPaiement: [],
        optionType: [   
              { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" },
        { value: "option4", text: "Option 4" }],
        optionAssure: [   
              { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" },
        { value: "option4", text: "Option 4" }],
        
        type_fact: '',
        valueTotal: '',
        Assure: '',
  
      };
    },
    created(){
        this.all_clients({
        page: 1,
        per_page: 1000000,
      
      });
      this.all_users({
        page: 1,
        per_page: 1000000,
      
      });
    },
    computed: {
    ...mapGetters([
    
      "getAllclients",
      "getAllUsers",
    ]),
  },
    methods: {
        ...mapActions([
     
      "all_clients",
      "all_users",
     
    ]),
    openModal(type) {
      event.preventDefault();
      if (type === "client") {
        this.$root.$emit("bv::show::modal", "clientStoreModal");
      }
      if (type === "user") {
        this.$root.$emit("bv::show::modal", "userModal");
      } 
    },
      handleInput(field, value) {
        this.$emit('update-input', { field, value });
      }
    }
  };
  </script>
  <style>
.newstore {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
</style>