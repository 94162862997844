<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>
        <span v-if="query && getentreprise"> {{ getentreprise.lib }}/</span>
        {{ $t("CLIENTS") }}
      </h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore()"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
        {{ $t("CUSTOMER") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>
    <div class="content">
      <div class="content-header">
        <searchBar
          :searchFunc="all_clients"
          :showFiltre="true"
          :searchText="searchBarText"
          :filtre="{
            entreprise_id: current_entreprise.id,
            type: this.currenttype,
          }"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group
                :label="$t('COMPANY_ID')"
                v-if="!query && isSuperAdmin"
              >
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
              <b-form-group :label="$t('Catégories')">
                <b-form-select
                  v-model="filtre_type"
                  :options="options"
                ></b-form-select>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button
                variant="success"
                @click="
                  setup(true);
                  hideModal('entrepriseModal');
                "
              >
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowclient }} {{ $t("CLIENTS") }}</p>

        <div class="productTrie" v-if="currenttype != null">
          {{ currenttype }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="deleteCurrentType()"
          />
        </div>

        <div class="productTrie" v-if="current_entreprise.lib != null">
          {{ current_entreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th v-if="isSuperAdmin" style="border-radius: 5px 0px 0px 0px">
                {{ $t("SOCIETY") }}
              </th>
              <th>
                {{ $t("TYPE") }}
              </th>
              <th>
                {{ $t("NAME") }}
              </th>
              <th>
                {{ $t("SURNAME") }}
              </th>
              <th>
                {{ $t("POSTAL_CODE") }}
              </th>
              <th>
                {{ $t("CITY") }}
              </th>
              <th>
                {{ $t("PHONE") }}
              </th>
              <th>
                {{ $t("EMAIL") }}
              </th>
              <th>
                {{ $t("CREATED_AT") }}
              </th>
              <th>
                {{ $t("STATUT_DEVIS") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("STATUT_FACTURE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllclients"
              :key="i"
              @click.prevent.stop="handleClient(data)"
            >
              <td v-if="isSuperAdmin" aria-colindex="1" role="cell">
                {{ data.entreprise.lib }}
              </td>
              <td aria-colindex="2" role="cell">
                <span v-if="data.type == 'type.particulier'">Particulier</span>
                <span v-else>Professionnel</span>
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.nom }}
              </td>
              <td aria-colindex="4" role="cell">
                {{ data.prenom }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.cp }}
              </td>
              <td aria-colindex="6" role="cell">
                {{ data.ville }}
              </td>
              <td aria-colindex="7" role="cell">
                {{ data.phone ? data.phone.formated_phone : "-" }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.email }}
              </td>
              <td aria-colindex="9" role="cell" v-if="data.created_at">
                {{ moment(data.created_at).format("DD-MM-YYYY hh:mm:ss") }}
              </td>
              <td aria-colindex="9" role="cell" v-else>-</td>
              <td aria-colindex="10" role="cell">
                <span v-if="data.devis_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.devis_signed == 'stored'" class="end">
                  {{ $t("VALIDATE") }}
                </span>
                <span v-else class="none">{{ $t("CANCEL") }}</span>
              </td>
              <td aria-colindex="11" role="cell">
                <span v-if="data.facture_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.facture_signed == 'stored'" class="end">
                  {{ $t("VALIDATE") }}
                </span>
                <span v-else class="none">{{ $t("CANCEL") }}</span>
              </td>
              <td aria-colindex="12" role="cell" class="action_col">
                <b-button
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <deleteModalVue
        :loader="getclientLoading"
        :elemToDelete="clientToDelete"
        :elemDelete="delete_client"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowclient"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getclientLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/client"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import moment from "moment";
import searchInput from "../components/ui/searchInput.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
  email,
} from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/clientmodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    storeModalVue,

    CommentMarche,
  },
  data() {
    return {
      downloadUrl: domains.download,
      filetoupdate: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        // revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      document: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      search: "",
      clientToConfig: {
        revenu_avis_imposition: null,
        type: null,
        pays: {
          iso_code: null,
        },
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        company_phone: {
          phone_number: null,
          iso_code: null,
        },
      },
      clientToConfigRef: null,
      clientToDelete: null,
      clientDetail: {
        type: null,
        email: null,
        tel: null,
        rue: null,
        ville: null,
        revenu_avis_imposition: null,
      },
      clientDetailRef: null,
      page: 1,
      perPage: 10,

      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      options: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      current_entreprise: {
        id: null,
      },
      filtre_type: null,
      currenttype: null,
      errorU: null,
      documents: null,
      infoClient: 0,
      loadingInfo: false,
      step: 1,
      resultValid: null,
      phoneValid: null,
    };
  },
  validations() {
    return {
      clientToConfig: {
        type: { required },
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        phone: {
          phone_number: { required },
        },
        pays: { required },
        entreprise: { requiredIf: requiredIf(this.isSuperAdmin) },
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_phone: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        // revenu_avis_imposition:{
        //   required
        // },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        email: { required, email, maxLength: maxLength(50) },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_countries",
      "all_users",
      "get_data_file",
      "allCategorie",
      "edit_client",
      "all_clients",
      "delete_client",
      "all_entreprises",
      "client",
      "add_to_datafile",
      "delete_from_datafile",
      "update_datafile",
      "devis",
      "factures",
      "all_rendezvous",
      "pays",
      "entreprise",
    ]),

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },
    resetModal() {
      this.documents = null;
      this.step = 1;
      this.document = {
        lib: null,
        description: null,
        categorie: null,
        file: null,
      };
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        // revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      };
      this.errorS = null;
      this.errorD = null;
      this.errorU = null;
      this.$refs["ModelConfigclient"].hide();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleUpdate(CAT, ref) {
      this.clientToConfig = { ...CAT };
      this.clientToConfigRef = ref;
      this.$router.push("/modifie/client/" + this.clientToConfig.id);
    },
    handleClient(CAT) {
      this.infoClient = 0;
      this.clientDetailRef = CAT;
      this.clientDetail = { ...CAT };

      this.goDoc();
      this.$router.push("/detail/client/" + this.clientDetail.id);
    },
    handleStore() {
      this.$router.push("/ajoute/client");
    },
    handleDelete(CAT) {
      this.clientToDelete = { ...CAT };
      this.$refs["Modelclient"].hide();
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    pagination(paginate) {
      this.page = paginate;
      this.all_clients({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        entreprise_id: this.current_entreprise.id,
      });
    },

    deleteCurrentType() {
      this.filtre_type = null;
      this.setup();
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      this.search = "";
      if (query) {
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: query.id,
        });

        this.current_entreprise = query;
      } else {
        this.current_entreprise = {
          lib: null,
        };
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
        });
      }

      this.hideModal("entrepriseModal");
    },

    goDoc() {
      this.step = 1;
      this.loadingInfo = true;
      this.get_data_file(this.clientDetail).then(() => {
        this.infoClient = 1;
        this.loadingInfo = false;
      });
    },

    setup(refresh = false) {
      if (refresh) {
        this.page = 1;
        this.search = null;
      }
      this.currenttype = this.filtre_type;
      if (this.$route.query.entreprise) {
        this.current_entreprise.id = this.$route.query.entreprise;
        this.entreprise({ id: this.$route.query.entreprise });
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          type: this.currenttype,
        });
      } else {
        if (this.isSuperAdmin) {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.current_entreprise.id,
            type: this.currenttype,
          });
        } else {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.getOnlineUser.entreprise.id,
            type: this.currenttype,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);

    if (this.isSuperAdmin) this.all_entreprises({ page: 1, per_page: 1000 });
    if (this.$route.query.id) {
      this.client(this.$route.query.id).then(() => {
        this.handleClient(this.getclient);
      });
    }
    if (this.isSuperAdmin || this.isentrepriseAdmin)
      this.all_users({ page: 1, per_page: 100 });
    this.allCategorie({ page: 1, per_page: 1000 });
    this.all_countries({ page: 1, perPage: 1000 });
  },

  computed: {
    ...mapGetters([
      "getAllclients",
      "getclientLoading",
      "getTotalRowclient",
      "getAllentreprises",
      "getentrepriseLoading",
      "getTabs",
      "getclient",
      "getOnlineUser",
      "getdictionnaire",
      "getSelectedLang",
      "getentreprise",
    ]),

    query() {
      return this.$route.query.entreprise;
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isProfessionel() {
      var bool = false;
      if (this.clientToConfig.type == "type.professionnel") bool = true;
      return bool;
    },
    searchBarText() {
      return `${this.$t("CLIENT_SEARCH")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")} )`;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    /* margin-top: 55px; */
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
.titresociete {
  color: #334081;
  width: 100%;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}
body {
  background: #fafdff;
}

.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.corps {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 2px solid #e9ecef;

    .left {
      display: flex;
      align-items: center;

      .circle {
        min-width: 52px;
        min-height: 52px;
        border-radius: 50%;
        background-color: #28367a;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 800;
        margin-right: 8px;
        text-transform: uppercase;
      }

      .pro {
        background-color: #287a6c;
      }

      .info {
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 16px;
          font-weight: 800;
        }
        p {
          font-size: 14px;
          color: #515151;
        }
      }
    }

    .right {
      .action {
        background-color: #28367a;
        border-radius: 4px;
        color: #fff;
        padding: 10px 0;
        font-size: 13px;
        display: flex;
        width: 130px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          line-height: 14px;
        }
      }
    }
  }

  hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 32px;
    border-left: 2px solid #d8d8d8;
    margin: 12px;
  }

  .hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 42px;
    margin-right: 12px;
    border-left: 2px solid #d8d8d8;
  }
  .information {
    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    margin: 12px 0;
    padding: 8px 12px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 7px;
    box-shadow: 1px 1px 2px 0px #15223214;
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      width: 33%;

      .icone {
        width: 15%;
        margin-right: 16px;
        font-weight: 400;
        color: #28367a;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: #515151;
        font-size: 14px;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
  }

  footer {
    @media only screen and (max-width: 900px) {
      padding: 0;
    }
    padding: 24px 12px;
    background-color: #fff;
    border-radius: 7px;
    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .type__doc {
        width: 24.5%;
        padding: 8px 0;
        text-align: center;
        font-size: 12px;
        background-color: #e9ecef;
        color: #000;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
      }

      .active {
        background-color: #f5f6f8;
        font-weight: 600;
        font-size: 14px;
        position: relative;

        &::after {
          @media only screen and (max-width: 900px) {
            left: 25%;
          }
          content: "";
          width: 40px;
          border-top: 2px solid #28367a;
          position: absolute;
          left: 40%;
          bottom: 7px;
        }
      }
    }

    .liste {
      padding: 8px;
      box-shadow: 1px 1px 2px 0px #15223214;
      background-color: #f5f6f8;
      cursor: pointer;
      top: -2px;
      position: relative;

      p {
        margin: 0;
        text-align: center;
      }

      .client_file,
      .devis_facture {
        border-radius: 7px;
        h2,
        p {
          margin: 0;
          display: flex;
          justify-content: center;
          width: 113px;
        }
        h2 {
          @media only screen and (max-width: 900px) {
            font-size: 12px;
          }
          overflow: hidden;
          font-size: 14px;
          color: #000;
          width: 128px;
        }
        p {
          @media only screen and (max-width: 900px) {
            font-size: 10px;
          }
          font-size: 12px;
          color: #515151;
          .status {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #ffb300;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .stat {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .none {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
        }

        .status {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #ffb300;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .stat {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .none {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .description {
          width: 30%;
          text-align: left;
        }
      }

      .devis_facture,
      .client_file {
        @media only screen and (max-width: 900px) {
          padding: 8px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background-color: #fff;
        color: #28367a;
        margin-bottom: 6px;

        &:hover {
          background-color: #eef1fd;
        }

        .info {
          text-align: center !important;
          h2,
          p {
            margin: 0;
          }
          h2 {
            @media only screen and (max-width: 900px) {
              font-size: 12px;
            }
            font-size: 14px;
            font-weight: 800;
            color: #000;
          }
          p {
            font-size: 12px;
            color: #515151;
          }
        }

        .action {
          @media only screen and (max-width: 900px) {
            width: 25%;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 15%;
          div {
            @media only screen and (max-width: 900px) {
              width: 20px;
              height: 20px;
            }
            background-color: #28367a;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            font-size: 12px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .agenda {
      padding: 8px;
      background-color: #f5f6f8;

      h1 {
        width: 100%;
        padding: 4px 0;
        text-align: center;
        background-color: #28367a;
        border-radius: 7px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-top: 8px;
      }

      .event {
        padding: 8px 32px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 4px;
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 14px;
          font-weight: 800;
          color: #000;
        }
        p {
          font-size: 12px;
          color: #515151;
        }
      }
    }

    .load_doc {
      width: 100%;
      background-color: #f5f6f8;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.langues {
  background-color: #fff;
  padding: 8px 0;
  .lang {
    background-color: #f5f6f8;
    display: block;
    text-decoration: none;
    padding: 10px 24px;
    color: #28367a;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 4px;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

.svgtele {
  width: 13.5px;
  height: 15px;
}
.svg {
  height: 22px;
  width: 80%;
  margin-left: 3px;
}

.fa-chevron-right {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}
.error-message {
  font-size: 16px !important;
}
</style>
