import Entreprise from "./entreprise";
export default class Client {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.full_name = data.nom + " " + data.prenom;
    this.email = data.email;
    this.rue = data.rue;
    this.cp = data.cp;
    this.ville = data.ville;
    this.tel = data.tel;
    this.phone = data.phone;
    this.type = data.type;
    this.entreprise = Entreprise.create(data.entreprise);
    this.devis_signed = data.devis_signed;
    this.facture_signed = data.facture_signed;
    this.company = data.company;
    this.company_email = data.company_email;
    this.company_tel = data.company_tel;
    this.company_phone = data.company_phone;
    this.pays = data.pays;
    this.SWIFT_BIC = data.SWIFT_BIC;
    this.forme_juridique=data.forme_juridique;
    this.naf=data.naf;
    this.ape=data.ape;
    this.IBAN = data.IBAN;
    this.siret = data.siret;
    this.num_tva = data.num_tva;
    this.titulaire_compte = data.titulaire_compte;
    this.poste = data.poste;
    this.commentaire = data.commentaire;
    this.created_at = data.created_at;
    this.zone = data.zone; 
    this.precarite = data.precarite;
    // this.type_chauffage = data.type_chauffage;
    // this.type_logement = data.type_logement;
    this.revenu_avis_imposition=data.revenu_avis_imposition;
    this.num_client=data.num_client;
    this.ape=data.ape;
    this.naf=data.naf;
    this.forme_juridique=data.forme_juridique;
  }
  update(data) {
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.email = data.email;
    this.rue = data.rue;
    this.cp = data.cp;
    this.ville = data.ville;
    this.tel = data.tel;
    this.phone = data.phone;
    this.company_phone = data.company_phone;
    this.type = data.type;
    this.devis_signed = data.devis_signed;
    this.facture_signed = data.facture_signed;
    this.company = data.company;
    this.company_email = data.company_email;
    this.company_tel = data.company_tel;
    this.pays = data.pays;
    this.SWIFT_BIC = data.SWIFT_BIC;
    this.forme_juridique=data.forme_juridique;
    this.naf=data.naf;
    this.ape=data.ape;
    this.IBAN = data.IBAN;
    this.siret = data.siret;
    this.num_tva = data.num_tva;
    this.titulaire_compte = data.titulaire_compte;
    this.poste = data.poste;
    this.commentaire = data.commentaire;
    this.created_at = data.created_at;
    this.zone = data.zone; 
    this.precarite = data.precarite;
    // this.type_chauffage = data.type_chauffage;
    // this.type_logement = data.type_logement;
    this.revenu_avis_imposition=data.revenu_avis_imposition;
    this.num_client=data.num_client;
  }

  static create(data) {
    return new Client(data);
  }
}
