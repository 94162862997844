import domain from "@/environment";
import axios from "axios";
import Facture from "../../models/facture";

const state = {
  facture: null,
  factures: [],
  acompte: null,
  acomptes: [],
  acompteRows: 0,
  factureRows: 0,
  factureLoading: false,
  error: null,
  factureMonoFiche: null,
};
const getters = {
  getFacture: (state) => state.facture,
  getAllFacture: (state) => state.factures,
  getfactureRows: (state) => state.factureRows,
  getFactureLoading: (state) => state.factureLoading,
  getAcompte: (state) => state.acompte,
  getAllAcomptes: (state) => state.acomptes,
  getAcompteRows: (state) => state.acompteRows,
  getFactureError: (state) => state.error,
  getfactureMonoFiche: (state) => state.factureMonoFiche,
};
const mutations = {
  SET_FACTURE_MONO_FICHE(state, payload) {
    if (payload) {
      state.factureMonoFiche = payload;
    } else {
      state.factureMonoFiche = null; 
    }
  }, 
  DO_NOTHING() {},
  SET_ERROR(state, payload) {
    if (payload) {
      state.error = payload;
    } else {
      state.error = null;
    }
  },
  SET_ONE_FACTURE(state, payload) {
    if (payload) {
      state.facture = payload;
    } else {
      state.facture = null;
    }
  },
  SET_FACTURES(state, payload) {
    if (payload) {
      state.factures = payload;
    } else {
      state.factures = [];
    }
  },
  SET_ONE_ACOMPTE(state, payload) {
    if (payload) {
      state.acompte = payload;
    } else {
      state.acompte = null;
    }
  },
  SET_ACOMPTES(state, payload) {
    if (payload) {
      state.acomptes = payload;
    } else {
      state.acomptes = [];
    }
  },
  PUSH_FACTURES(state, payload) {
    state.factures.push(Facture.create(payload));
  },
  UPDATE_FACTURE(state, payload) {
    for (let index = 0; index < state.factures.length; index++) {
      if (state.factures[index].id == payload.id) {
        state.factures[index] = payload;
        break;
      }
    }
  },
  SET_LOADINGFACTURE(state, payload) {
    if (payload) {
      state.factureLoading = payload;
    } else {
      state.factureLoading = false;
    }
  },
  DELETE_FACTURE(state, payload) {
    for (let index = 0; index < state.factures.length; index++) {
      if (state.factures[index].id == payload.id) {
        state.factures.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_FACTURE_ROW(state, payload) {
    if (payload) {
      state.factureRows = payload;
    } else {
      state.factureRows = 0;
    }
  },
  UPDATE_ACOMPTE_ROW(state, payload) {
    if (payload) {
      state.acompteRows = payload;
    } else {
      state.acompteRows = 0;
    }
  },
};

const actions = {
  setFactureMonoFiche({ commit }, updatedDevisObject) {
    commit("SET_FACTURE_MONO_FICHE", updatedDevisObject);
  },
  async factures({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      start_date: payload.start_date,
      end_date: payload.end_date,
      client: payload.client,
      entreprise: payload.entreprise ? payload.entreprise.id : null,
    };
    try {
      const responce = await axios.get(domain + `/factures`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_FACTURES", responce.data.data.data);
      commit("UPDATE_FACTURE_ROW", responce.data.data.total);
      commit("SET_LOADINGFACTURE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },
  async signed_Facture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.post(
        domain + `/yousign/${payload.doc}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_FACTURE", responce.data.data);
      commit("SET_LOADINGFACTURE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },
  async getonefacture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.get(domain + `/factures/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_ONE_FACTURE", responce.data.data);
      commit("SET_LOADINGFACTURE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },
  async store_facture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.post(domain + `/factures`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_FACTURES", responce.data.data);
      commit("UPDATE_FACTURE_ROW", state.factureRows + 1);
      commit("SET_LOADINGFACTURE", false);
      return responce;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      commit("SET_ERROR", error.response.data.message);
      throw error.response.data.message;
    }
  },

  async delete_facture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      await axios.delete(domain + `/factures/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_FACTURE", payload);
      commit("UPDATE_FACTURE_ROW", state.factureRows - 1);
      commit("SET_LOADINGFACTURE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async downloadFacture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.get(
        domain + `/facture/download/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_LOADINGFACTURE", false);
      return responce.data.data.pdf;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async email_facture({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      await axios.get(domain + `/facture/send/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LOADINGFACTURE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async acomptes({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise: payload.entreprise,
    };
    try {
      const responce = await axios.get(domain + `/acomptes`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_ACOMPTES", responce.data.data.data);
      commit("UPDATE_ACOMPTE_ROW", responce.data.data.total);
      commit("SET_LOADINGFACTURE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async getoneacompte({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.get(domain + `/acomptes/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_ONE_ACOMPTE", responce.data.data);
      commit("SET_LOADINGFACTURE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async downloadAcompte({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      const responce = await axios.get(
        domain + `/facture/download_acompte/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_LOADINGFACTURE", false);
      return responce.data.data.pdf;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },

  async email_acompte({ commit }, payload) {
    commit("SET_LOADINGFACTURE", true);
    try {
      await axios.get(domain + `/facture/send_acompte/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LOADINGFACTURE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGFACTURE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
