<template>
  <div>
    <div v-if="isLoading" class="loading-overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
        class="loading-spinner"
      ></v-progress-circular>
    </div>
    <div class="innerContainer">
      <section class="banner-container-nd ml-5">
        <div class="banner-content">
          <div class=" mt-5">
            <div class=" d-block d-md-none mx-5">
              <multiselect
                v-model="selectedOption"
                :options="selectOptions"
                :multiple="false"
                placeholder="Select option"
                :label="'name'"
                @input="handleSelectChange"
                class="custom-multiselect"
              />
            </div>

         <div class="row">
          <div class=" cards p-0 mt-5 col-md-4 col-lg-2 d-none d-md-block">
              <div 
                v-for="(bar, index) in bars"
                :key="index"
                :class="{'active': activeIndex === index}"
                @click="setActive(index)"
              >
                <div class="p-3" :class="{'active-type': activeIndex === index}">
                  {{ bar.name }}
                </div>
                <div v-if="activeIndex === index && bar.categories" class="categories-container">
                  <div 
                    v-for="(category, catIndex) in bar.categories" 
                    :key="catIndex"
                    @click.stop="setCategoryActive(catIndex)"
                    :class="{'active-category': activeCategoryIndex === catIndex}"
                    class="category-item"
                  >
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-8 col-lg-9 pt-0">
              <component :is="categoryComponent" />
            </div>
         </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DevisTypesBARTH from "@/views/DevisTypesBARTH.vue";
import DevisTypesBAREN from "@/views/DevisTypesBAREN.vue";
import AllDevis from "@/views/AllDevis.vue";
import devisTypesLibre from "./devisTypesLibre.vue";
import { mapGetters,mapActions } from "vuex";
import soon from "@/views/BientotDisponible/soon.vue";
export default {
  components: {
    AllDevis,
    soon,
    DevisTypesBARTH,
    DevisTypesBAREN,
    devisTypesLibre,
    Multiselect
  },
  data() {
    return {
      data: {
    "brouillon": 1,
    "type_devis": "",
    "entreprise_id": "1",
    "modele_id": "14",
   
  },
  isLoading:false,
  selectedCards: [],
      activeIndex: 0,
      activeCategoryIndex: null,
      categoryComponent: "AllDevis",
      selectedOption: null, 
      bars: [
        {
          name: "All",
          component: "AllDevis",
        },
        // {
        //   name: "Libre",component: "AllDevis"
        // },
        {
          name: "BAR",
          categories: [
            { name: "BAR-EN", url: "", component: "DevisTypesBAREN" },
            // { name: "BAR-TH", url: "", component: "soon" },
            { name: "BAR-TH", url: "", component: "DevisTypesBARTH" },

          ],
        },
      ],
      selectOptions: [
        { name: "All", component: "AllDevis", default: true },
        // { name: "Libre" , component: "AllDevis"},
        { name: "BAR-EN", component: "DevisTypesBAREN" },
        // { name: "BAR-TH", component: "soon" },
        { name: "BAR-TH", component: "DevisTypesBARTH" },

      ],
    };
  },
  created() {
    const defaultOption = this.selectOptions.find(option => option.default) || this.selectOptions[0];
    this.selectedOption = defaultOption;
    this.categoryComponent = defaultOption.component;
  },
  computed: {
    ...mapGetters(["getTabs", "getOnlineUser", "getcreationEntreprise", "getdevisType"]),
    isSuperAdmin() {
      return this.getOnlineUser.role === "user.super-admin";
    },
    isEntrepriseAdmin() {
      return this.getOnlineUser.role === "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role === "user.commercial-entreprise";
    },
    isAssistant() {
      return this.getOnlineUser.role === "user.assistant";
    },
  },
  methods: {
    ...mapActions(["store_devis", "updateCreationEntreprise", "devisTypeAdd","addGroupe"]),

    async createDevis(typeDevis) {
      try {
        this.isLoading=true;
        this.data.type_devis = typeDevis;
        this.data.entreprise_id = this.getOnlineUser.entreprise.id;
        const response = await this.store_devis(this.data);
        if (this.selectedCards.length > 0) {
          const groupes = this.selectedCards.map(() => ({
  document_id: response.data?.id,
  type_group: 'Libre',
}));

for (const groupe of groupes) {
  await this.addGroupe(groupe); 
}
        } else {
          const groupes = 
            {
              document_id:response.data?.id,
              type_group:typeDevis,
            };
          await this.addGroupe(groupes)
        }

      
        this.id = response.data?.devis?.id;
    
    this.$router.push(`/devis/manuel/${this.id}`);
    this.isLoading=false;
  
      } catch (error) {
        this.isLoading=false;
        throw new Error(
      error
    );      }
    }, 
    setActive(index) {
      this.activeIndex = index;
      const selectedBar = this.bars[index];

      if (!selectedBar.categories) {
        if( selectedBar.name=="Libre"){
          this.createDevis('Libre')
        }
        
        this.categoryComponent = selectedBar.component;
        this.activeCategoryIndex = null; 
      } else {
        this.activeCategoryIndex = null;
        this.categoryComponent = this.bars[0].component; 
      }
    },
    setCategoryActive(index) {
      this.activeCategoryIndex = index;
      const category = this.bars[this.activeIndex]?.categories?.[index];
      if (category?.component) {
        this.categoryComponent = category.component;
      }
      if (category?.url) {
        window.open(category.url, "_blank");
      }
    },
    handleSelectChange(selected) {
  if (selected) {
    const selectedBar = this.selectOptions.find(option => option.name === selected.name);
    if (selectedBar) {
      if (selectedBar.name === "Libre") {
        this.createDevis("Libre"); // Appeler la fonction createDevis si "Libre" est sélectionné
      }
      this.categoryComponent = selectedBar.component;
      this.activeCategoryIndex = null;
    }
  }
},

  
  },
};
</script>



  <style scoped>
  .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loading-spinner {
  z-index: 10000;
}
  .custom-multiselect {
  width: 50% !important; 
}
  .innerContainer {
    margin-right: 0;
    padding: 15px 0px 15px;
    min-height: 100vh;
}


  .cards {
    background-color: #24316d;
    color: white;
    height: 30%;

  
  }
  
  .cards div {
    cursor: pointer;

  }
  
  .active-type {
    color: white;
    background-color: #3a4891;
    border-left: 5px solid red;
  }
  
  .categories-container {
   background-color: #24316d;
    color: white;
  }
  
  .category-item {
    cursor: default;
    color: lightblue;
    text-decoration: underline;
    padding: 20px;
    background-color: transparent;
   
  }
  
  
  .category-item.active-category {
    background-color: #7f90e9bd;
    border-left: 5px solid red;
    padding: 15px;
    color: white; 
  }
  </style>
  