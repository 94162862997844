<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="chauffeEauAdd">
            <div class="center mt-3 p-3">
              <div>
                <b-form-radio
                  v-model="selected"
                  class="mb-3"
                  name="radios1"
                  value="A"
                >
                  {{$t("certification")}}
                </b-form-radio>

                <div class="mx-3" v-if="selected === 'A'">
                  <div>
                    <b-form-radio
                      v-model="selectedNorme"
                      class="mb-3"
                      name="radios2"
                      value="C"
                    >
                      {{$t("fondée_sur_les_normes")}}
                    </b-form-radio>
                    <div class="fieldset my-3" v-if="selectedNorme === 'C'">
                      <div class="legend mb-2">
                        {{ $t("NOM_CERTIF") }}
                      </div>
                      <b-form-input
                        type="text"
                        v-model="chauffeEauData.nom_certification"
                        :placeholder="$t('NOM_CERTIF')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  
                    <b-form-radio
                      v-model="selectedNorme"
                      class="mb-3"
                      name="radios2"
                      value="D"
                    >
                     {{$t("Autres_normes")}}
                    </b-form-radio>

                    <div v-if="selectedNorme === 'D'">
                      <div class="fieldset mt-3">
                        <div class="legend mb-2">
                          {{ $t("NOM_CERTIF") }}
                        </div>
                        <b-form-input
                          type="text"
                          v-model="chauffeEauData.nom_certification"
                          :placeholder="$t('NOM_CERTIF')"
                          class="form-input custom-placeholder custom-input"
                        >
                        </b-form-input>
                      </div>

                      <div class="fieldset my-3">
                        <div class="legend mb-2">
                          {{ $t("NORM") }}
                        </div>
                        <b-form-input
                          type="text"
                          v-model="chauffeEauData.norme"
                          :placeholder="$t('NORM')"
                          class="form-input col- custom-placeholder custom-input"
                        >
                        </b-form-input>
                      </div>

                     
                    </div>
                  </div>
                </div>
                <b-form-radio
                  v-model="selected"
                
                  name="radios1"
                  value="B"
                >
                  {{$t("capteurs_posèdent_caractéristiques_performances")}}
                </b-form-radio>
              </div>
           
            </div>
            <div class="fieldset mx-3 mb-2" >
              <div class="legend mb-2">
                {{ $t("FLUIDE") }}
              </div>
              <b-form-input
                type="text"
                v-model="chauffeEauData.nature_fluide"
                :placeholder="$t('FLUIDE')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
          </form>
          <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div>
        </div>
      </div>
    </div>
    
    <div  v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Snackbar,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      isLoading:false,
      selected: "A",
      selectedNorme: "C",
      chauffeEauData: null,
      chauffeEau: false,
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.chauffeEauData = this.initializeChauffeEau();
      this.setSelectedValues();
    }
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },
  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.chauffeEauData = this.initializeChauffeEau();
          this.chauffeEau = newDevis.data?.devis.chauffe_eau !== null;
          this.setSelectedValues();
          if (this.chauffeEau) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche", "editChauffeEau", "storeChauffeEau"]),
    setSelectedValues() {
      const norme = this.chauffeEauData.norme;

      if (
        norme ===
        "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen"
      ) {
        this.selected = "B";
      } else {
        this.selected = "A";
      }

      if (this.selected === "A" && norme === "ISO 9806 et NF EN 12975") {
        this.selectedNorme = "C";
      } else {
        this.selectedNorme = "D";
      }
    },
    initializeChauffeEau() {
      const chauffeEau = this.getdevisMonoFiche?.data?.devis.chauffe_eau || {};
      return {
        id: chauffeEau.id,
        have_norme: chauffeEau.have_norme || false,
        have_certification: chauffeEau.have_certification || false,
        nom_certification: chauffeEau.nom_certification || "",
        norme: chauffeEau.norme || "",

        nature_fluide: chauffeEau.nature_fluide || "",
      };
    },
    async chauffeAdd() {
      if (this.selected === "B") {
        this.chauffeEauData.have_certification = false;
        this.chauffeEauData.have_norme = true;
        this.chauffeEauData.norme =
          "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen";
      } else {
        this.chauffeEauData.have_certification = true;
        this.chauffeEauData.have_norme = true;
        if (this.selectedNorme === "C") {
          this.chauffeEauData.norme = "ISO 9806 et NF EN 12975";
        }
      }

      const response = await this.storeChauffeEau(this.chauffeEauData);
      this.getdevisMonoFiche.data.devis.chauffe_eau_id = response.data.id;
      this.getdevisMonoFiche.data.devis.chauffe_eau = {
        id: response.data.id,
        nature_fluide: this.chauffeEauData.nature_fluide,
        nom_certification: this.chauffeEauData.nom_certification,
        norme: this.chauffeEauData.norme,
        have_norme: this.chauffeEauData.have_norme,
        have_certification: this.chauffeEauData.have_certification,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return response;
    },

    async chauffeEauAdd() {
      if (this.chauffeEau) {
        if (this.selected === "B") {
          this.chauffeEauData.have_certification = false;
          this.chauffeEauData.have_norme = true;

          this.chauffeEauData.nom_certification = " ";
          this.chauffeEauData.norme =
            "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen";
        } else {
          this.chauffeEauData.have_certification = true;
          this.chauffeEauData.have_norme = true;
          if (this.selectedNorme === "C") {
            this.chauffeEauData.norme = "ISO 9806 et NF EN 12975";
          }
        }
        const chauffe = {
          ...this.chauffeEauData,
          id: this.getdevisMonoFiche.data.devis.chauffe_eau.id,
        };
        const reponse = await this.editChauffeEau(chauffe);
        this.getdevisMonoFiche.data.devis.chauffe_eau = {
          id: this.getdevisMonoFiche.data.devis.chauffe_eau.id,
          nature_fluide: this.chauffeEauData.nature_fluide,
          nom_certification: this.chauffeEauData.nom_certification,
          norme: this.chauffeEauData.norme,
          have_norme: this.chauffeEauData.have_norme,
          have_certification: this.chauffeEauData.have_certification,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      const res = await this.chauffeAdd();
      this.getdevisMonoFiche.data.devis.chauffe_eau_id = res.data.id;
      this.getdevisMonoFiche.data.devis.chauffe_eau = {
        id: res.data.id,
        nature_fluide: this.chauffeEauData.nature_fluide,
        nom_certification: this.chauffeEauData.nom_certification,
        norme: this.chauffeEauData.norme,
        have_norme: this.chauffeEauData.have_norme,
        have_certification: this.chauffeEauData.have_certification,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return res;
    },
    async goToStep() {
      this.isLoading=true;
      if(this.chauffeEauData.nature_fluide==""){
        this.isLoading=false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;
      }
      await this.chauffeEauAdd();
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },
    async goToNextStep() {
      this.isLoading=true;
      if(this.chauffeEauData.nature_fluide==""){
        this.isLoading=false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
   
        return;
      }
      await this.chauffeEauAdd();
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
