<!-- <template>
  <div class="mx-5 my-2">
  
    <div v-if="title">
      <b>
        {{ $t(title) }}
      </b>
    </div>
    <form @submit.prevent="submitForm">
    <div class="row">
      <div
        v-for="(field, index) in fields"
        :key="index"
        :class="getResponsiveClass(fields.length)"
      >
        <b-form-group :label="$t(field.label)">
          <multiselect
            v-if="field.type === 'multiSelect'"
            v-model="formData[field.key]"
            :options="field.options || []"
            :multiple="field.multiple !== false"
            :required="field.required"
            track-by="value"
            label="text"
            placeholder="Sélectionnez des options"
            :disabled="field.disabled"
            :searchable="true"
            class="custom-placeholder custom-input"
          ></multiselect>

          <b-form-input
            v-else
            :type="field.type"
            :class="
              field.inputClass || 'form-input custom-placeholder custom-input'
            "
            :required="field.required"
            v-model="formData[field.key]"
          ></b-form-input>
        </b-form-group>
      </div>
    </div></form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Multiselect,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    initialValues: {
      type: Function,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(newValues) {
        this.setFactureMonoFiche(newValues)
     
      },
    },
  },
  mounted() {
    const initialValues = this.initialValues();
    this.fields.forEach((field) => {
      this.$set(
        this.formData,
        field.key,
        initialValues[field.key] || (field.type === "multiSelect" ? [] : "")
      );

      if (field.type === "multiSelect" && typeof field.getStat === "function") {
        this.getStatOptions(field);
      }
    });
  },
  computed: {
    ...mapGetters([
      "getTabs",
      "getfactureMonoFiche",
      "getAllentreprises",
      "getAllclients",
      "getAllUsers",
    ]),
  },
  methods: {
    ...mapActions([
      "entreprise",
      "all_entreprises",
      "all_clients",
      "all_users",
      "setFactureData",
      "setFactureMonoFiche"
    ]),
    getResponsiveClass(totalFields) {
      if (totalFields <= 2) return "col-lg-6 col-md-6 col-sm-12";
      if (totalFields <= 3) return "col-lg-4 col-md-6 col-sm-12";
      return "col-lg-3 col-md-6 col-sm-12";
    },

    getStatOptions(field) {
      field.options = field.getStat();
    },
    submitForm() {
      this.$emit("submit", this.formData);
    },
  },
};
</script> -->
<template>
  <div class="mx-5">
    <div class="row my-2">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
          <b-form-input
            type="date"
            v-model="visite_technique"
            @input="handleInput('visite_technique', visite_technique)"
            class="form-input custom-placeholder custom-input"
            required
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12">
        <b-form-group :label="$t('DATE_START_DEVIS')">
          <b-form-input
            type="date"
            v-model="debut_devis"
            @input="handleInput('debut_devis', debut_devis)"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-12">
        <b-form-group :label="$t('DATE_END_DEVIS')">
          <b-form-input
            type="date"
            v-model="fin_devis"
            @input="handleInput('fin_devis', fin_devis)"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <b-form-group :label="$t('Debut travaux')">
          <b-form-input
            type="date"
            v-model="debut_travaux"
            @input="handleInput('debut_travaux', debut_travaux)"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <b-form-group :label="$t('Fin travaux')">
          <b-form-input
            type="date"
            v-model="fin_travaux"
            @input="handleInput('fin_travaux', fin_travaux)"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visite_technique: '',
      debut_devis: '',
      fin_devis: '',
      debut_travaux: '',
      fin_travaux: '',
    };
  },
  methods: {
    handleInput(field, value) {
      this.$emit('update-input', { field, value });
    }
  }
};
</script>





<style></style>
