<template>
    <div class="mx-5">
      <div class="row my-2">
        <div class="col-lg-6 col-md-6 col-sm-12">

              <b-form-group :label="$t('TYPE-FAC')">
                <multiselect
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="factypeoption"
                  label="text"
                  track-by="value"
                  v-model="type_fact"
                     @input="handleInput('type_fact', type_fact)"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
              </b-form-group>
           
        </div>
  
        <div class="col-lg-6 col-md-6 col-sm-12">
          <b-form-group :label="$t('valueTotal')">
            <b-form-input
              type="number"
              v-model="valueTotal"
              @input="handleInput('valueTotal', valueTotal)"
              required
              class="form-input custom-placeholder custom-input"
            ></b-form-input>
          </b-form-group>
        </div>
  
       
      </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
            <b-form-group :label="$t('ASSURANCE_TYPE')">
                <multiselect
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="optiontypeassurance"
                  label="text"
                  track-by="value"
                  v-model="assurance_type"
                   @input="handleInput('assurance_type', assurance_type)"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
              </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
           
          
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>

                <multiselect
                  v-model="selectedMoyensPaiement"
                  :searchable="false"
                  :close-on-select="true"
                  :options="optionspaiement"
                  :multiple="true"
                  :placeholder="$t('sélectionnez_moy')"
                  label="text"
                  track-by="value"
                  required
                     @input="handleInput('selectedMoyensPaiement', selectedMoyensPaiement)"
                  class="form-input custom-placeholder custom-input custom-margin-bottom"
                ></multiselect>
      
        </div>
  </div>
    
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        paiement_state: [],
        selectedUser:null,
      optionspaiement: [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ],
      selectedMoyensPaiement: [],
      factypeoption: [
        { value: "facture.acompte", text: this.$t("FACT-ACOMPTE") },
        { value: "facture.fin-travaux", text: this.$t("Fin travaux") },
      ],
        optiontypeassurance: [
        { value: "deces_invalidite", text: this.$t("DECES_INVALIDITE") },
        { value: "perte_emploi", text: this.$t("PERTE_EMPLOI") },
        {
          value: "deces_invalidite_perte_emploi",
          text: this.$t("DECES_INVALIDITE_PERTE_EMPLOI"),
        },
      ],
        type_fact: '',
        valueTotal: '',
        assurance_type: '',
  
      };
    },
    methods: {
   
    
      handleInput(field, value) {
        this.$emit('update-input', { field, value });
      }
    }
  };
  </script>