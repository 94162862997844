<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">
                {{ $t("conditions_technique") }} 
              </div>
            </div>
          </div>
          <form @submit.prevent="isolation_plancherAdd">
            <div>
              <label>
                {{
                  $t("Protéger les matériaux d'isolation contre l'humidité par")
                }}
              </label>
              <b-form-radio-group
                v-model="selected"
                class="mb-3"
                name="isolation-option"
              >
                <div class="row">
                  <div class="col-6">
                    <b-form-radio value="A">{{
                      $t("Pare-vapeur")
                    }}</b-form-radio>
                  </div>
                  <div class="col-6">
                    <b-form-radio value="B">{{ $t("Autre") }}</b-form-radio>
                  </div>
                </div>
              </b-form-radio-group>
            </div>
            <b-form-group
              :label="$t('Types_isolants_thermiques')"
              label-for="select-type-appareil"
            >
              <b-form-select
                v-model="type_isolant"
                :options="typeAppareilOptions"
                id="select-type-appareil"
                class="custom-input"
              >
              </b-form-select>
            </b-form-group>

            <b-form-group
              :label="$t('NORM')"
              label-for="select-norme"
              v-if="type_isolant"
            >
              <b-form-select
                v-model="norme"
                :options="normeOptions"
                id="select-norme"
                class="custom-input"
              >
              </b-form-select>
            </b-form-group>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("surface_isolant") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @paste="
                  (event) =>
                    onPaste(event, 'isolation_plancherData.Surface_isolant')
                "
                @keydown="(event) => onKeyDown(event)"
                v-model="isolation_plancherData.Surface_isolant"
                :placeholder="$t('surface_isolant')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("resistance_thermique") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
                @paste="
                  (event) =>
                    onPaste(
                      event,
                      'isolation_plancherData.resistance_thermique'
                    )
                "
                v-model="isolation_plancherData.resistance_thermique"
                :placeholder="$t('resistance_thermique')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("epaisseur") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
                @paste="
                  (event) => onPaste(event, 'isolation_plancherData.epaisseur')
                "
                v-model="isolation_plancherData.epaisseur"
                :placeholder="$t('epaisseur')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
          </form>
    
        </div>
      </div>
     
    </div>
    <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength<=1" @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
      
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div>
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from "@/components/ui/errorSnackbar.vue";

import { handlePaste, preventExceedingLengthFloat } from "@/utils/inputUtils";
export default {
  components: {
    Snackbar,
  },
  props: {
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      errorMessage: "",
      isLoading: false,
      selected: "A",
      selectedTypePose: null,
      isolation_plancherData: null,
      isolation_plancher: false,
      type_isolant: null,
      norme: null,
      options_type_pose: [
        { value: "en combles perdu", text: this.$t("en combles perdu") },
        {
          value: "en rampant de toitures",
          text: this.$t("en rampant de toitures"),
        },
      ],
      typeAppareilOptions: [
        { value: null, text: this.$t("Sélectionnez_type"), disabled: true },
        { value: "non reflechissant", text: this.$t("non_reflechissant") },
        { value: "reflechissant", text: this.$t("reflechissant") },
      ],
      normesByType: {
        "reflechissant": [{ value: "NF EN 16012+A1", text: "NF EN 16012+A1" }],
        "non reflechissant": [
          { value: "NF EN 12664", text: "NF EN 12664" },
          { value: "NF EN 12667", text: "NF EN 12667" },
          { value: "NF EN 12939", text: "NF EN 12939" },
        ],
      },
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.isolation_plancherData = this.initializeisolation_plancher();
      if (this.isolation_plancherData.id !== null) {
        this.isCompleted = true;
      }
      if (this.isolation_plancherData.necessite_pare_vapeur === 1) {
        this.selected = "A";
      } else {
        this.selected = "B";
      }
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getdevisMonoFiche"]),
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
    normeOptions() {
      return this.type_isolant ? this.normesByType[this.type_isolant] : [];
    },
  },

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.isolation_plancherData = this.initializeisolation_plancher();
          this.isolation_plancher =
            newDevis.data?.devis.isolation_plancher !== null;
          this.type_isolant =
            newDevis.data?.devis?.isolation_plancher?.type_isolant || null;
          this.norme = newDevis.data?.devis?.isolation_plancher?.norme || null;
          if (this.isolation_plancher) {
            this.isCompleted = true;
          }
          if (this.isolation_plancher.necessite_pare_vapeur === 1) {
            this.selected = "A";
          } else {
            this.selected = "B";
          }
        }
      },
      deep: true,
      immediate: true,
      "isolation_plancherData.type_pose": {
        handler(newValue) {
          this.selectedTypePose = newValue
            ? "en combles perdu"
            : "en rampant de toitures";
        },
        immediate: true,
        "isolation_plancherData.necessite_pare_vapeur": {
          handler(newValue) {
            this.selected = newValue === 1 ? "A" : "B";
          },
          immediate: true,
        },
      },
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),

    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    async postIsolationCombleToiture(payload) {
      try {
        const response = await axios.post(
          domain + `/isolation_plancher`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.join(", ");
        } else {
          this.errorMessage = "Vérifiez vos données.";
        }
        this.$refs.snackbar.showSnackbar(this.errorMessage);
        return null;
      }
    },
    async putIsolationCombleToiture(payload) {
      try {
        const response = await axios.put(
          domain + `/isolation_plancher/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to post isolation combles toiture.");
      }
    },
    initializeisolation_plancher() {
      const isolation_plancher =
        this.getdevisMonoFiche?.data?.devis.isolation_plancher || {};
      this.selectedTypePose = isolation_plancher.type_isolant
        ? "non reflechissant"
        : "reflechissant";

      return {
        id: isolation_plancher.id,
        necessite_pare_vapeur: isolation_plancher.necessite_pare_vapeur || 0,
        resistance_thermique: isolation_plancher.resistance_thermique || "",
        norme: isolation_plancher.norme || "",
        epaisseur: isolation_plancher.epaisseur || "",
        Surface_isolant: isolation_plancher.Surface_isolant || "",
        type_isolant: isolation_plancher.type_isolant || "",
      };
    },
    async isolationAdd() {
      if (this.selected === "A") {
        this.isolation_plancherData.necessite_pare_vapeur = 1;
      } else {
        this.isolation_plancherData.necessite_pare_vapeur = 0;
      }
      this.isolation_plancherData.type_isolant = this.type_isolant;
      this.isolation_plancherData.norme = this.norme;
      this.isolation_plancherData.type_pose = this.selectedTypePose;
      const response = await this.postIsolationCombleToiture(
        this.isolation_plancherData
      );

      if (response.id !== null) {
        this.getdevisMonoFiche.data.devis.isolation_plancher_id = response.id;
        this.getdevisMonoFiche.data.devis.isolation_plancher = {
          id: this.getdevisMonoFiche.data.devis.isolation_plancher_id,
          Surface_isolant: this.isolation_plancherData.Surface_isolant,
          epaisseur: this.isolation_plancherData.epaisseur,
          resistance_thermique:
            this.isolation_plancherData.resistance_thermique,
          norme: this.isolation_plancherData.norme,
          necessite_pare_vapeur:
            this.isolation_plancherData.necessite_pare_vapeur,
          type_pose: this.isolation_plancherData.type_pose,
          type_isolant: this.isolation_plancherData.type_isolant,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      } else {
        this.isLoading = false;

        return;
      }
    },

    async isolation_plancherAdd() {
      if (this.isolation_plancher) {
        if (this.selected === "A") {
          this.isolation_plancherData.necessite_pare_vapeur = 1;
        } else {
          this.isolation_plancherData.necessite_pare_vapeur = 0;
        }
        this.isolation_plancherData.type_isolant = this.type_isolant;
        this.isolation_plancherData.norme = this.norme;
        this.isolation_plancherData.type_pose = this.selectedTypePose;
        const chauffe = {
          ...this.isolation_plancherData,
          id: this.getdevisMonoFiche.data.devis.isolation_plancher.id,
        };

        const reponse = await this.putIsolationCombleToiture(chauffe);
        this.getdevisMonoFiche.data.devis.isolation_plancher = {
          id: this.getdevisMonoFiche.data.devis.isolation_plancher.id,
          Surface_isolant: this.isolation_plancherData.Surface_isolant,
          resistance_thermique:
            this.isolation_plancherData.resistance_thermique,
          epaisseur: this.isolation_plancherData.epaisseur,
          norme: this.isolation_plancherData.norme,
          necessite_pare_vapeur:
            this.isolation_plancherData.necessite_pare_vapeur,
          type_pose: this.isolation_plancherData.type_pose,
          type_isolant: this.isolation_plancherData.type_isolant,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
    },
    async goToStep() {
      this.isLoading = true;
      if (this.isolation_plancherData.Surface_isolant == "") {
        this.isLoading = false;
        this.isCompleted = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
      }

      await this.isolation_plancherAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;
        this.isCompleted = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        return;
      }
 
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }
      this.isLoading = false;
      this.isCompleted = false;
    },
    async goToNextStep() {
      this.isLoading = true;
      if (this.isolation_plancherData.Surface_isolant == "") {
        this.isLoading = false;
        this.$emit("changeStep", 6, true);
      }

      await this.isolation_plancherAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;

        return;
      }
      this.isLoading = false;
      this.$emit("changeStep", 6, true);
    },

    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
