<template>
  <b-modal
    scrollable
    id="CategorieFichierModal"
    ref="CategorieFichierModal"
    hide-footer
  >
    <template #modal-header>
      <h5>{{ $t("NEW") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addGatigorie">
      <div class="center">
        <b-form-group :label="$t('WORDING')">
          <b-form-input
            v-model="newCategorie.lib"
            required
            maxLength="50"
          ></b-form-input>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>
      </div>
      <div class="double">
        <b-alert variant="warning" show v-if="errorS" class="messageError">
          {{ errorS }}
        </b-alert>
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" type="submit">
          <div class="block-spinner">
            {{ $t("CONFIRM") }}
            <div v-if="getLoadingcategorieMetier" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
export default {
 
  data() {
    return {
    
      page: 1,
      perPage: 10,
      newCategorie: {
        lib: null,
      },
      erreurlist: {
        lib: null,
      },
      errorS: false,
    
    };
  },
  methods: {
    ...mapActions([
      "allcategorieMetier",
      "store_categorieMetier",
    ]),

   
   
    resetModal() {
      this.newCategorie = {
        lib: null,
      };
      this.erreurlist = {
        lib: null,
      };
    
      this.errorS = null;
  
      this.$refs["CategorieFichierModal"].hide();
  
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
   
   
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async addGatigorie() {
      this.errorS = null;
      this.erreurlist = {
        lib: null,
      };
      await this.store_categorieMetier(this.newCategorie)
        .then(() => {
          this.errorS = null;
          this.hideModal("CategorieFichierModal");
          this.resetModal();
          this.setup(true);
        })
        .catch((err) => {
          for (const [key, value] of Object.entries(err)) {
            if (!this.erreurlist[key]) {
              this.erreurlist[key] = value;
            } else {
              this.errorS = err;
            }
          }
        });
    },
   

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.allcategorieMetier({ page: this.page, per_page: this.perPage });
    },
  },
  mounted() {
    this.setup(true);
  },
  computed: {
    ...mapGetters([
      "getLoadingcategorieMetier"
    ]),
  },
};
</script>