import axios from "axios";
import domain from "@/environment";

const state = {
  groupes: [],
  groupe: null,

};

const getters = {
  getGroupes: (state) => state.groupes,
  getGroupe: (state) => state.groupe,
 
};

const mutations = {
  REMOVE_GROUP(state, id) {
    // Vérifier si state.groupes est un tableau avant d'utiliser .filter
    if (Array.isArray(state.groupes)) {
      state.groupes = state.groupes.filter(group => group.id !== id);
    }
  },
  SET_GROUPES(state, payload) {
    state.groupes = payload || [];
  },
  SET_GROUPE(state, payload) {
    state.groupe = payload || null;
  },
  ADD_GROUPE(state, payload) {
    state.groupes.push(payload);
  },
  UPDATE_GROUPE(state, payload) {
    const index = state.groupes.findIndex((groupe) => groupe.id === payload.id);
    if (index !== -1) {
      state.groupes.splice(index, 1, payload);
    }
  },

};

const actions = {
  async fetchGroupes({ commit }, document_id) {

    try {
      const response = await axios.get(`${domain}/groupelignedocuments`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        params: { document_id },
      });
      commit("SET_GROUPES", response.data.data);
    } catch (error) {
      throw error.response?.data.message || error.message;
    } 
  },
  async fetchOneGroup({ commit }, id) {
    try {
      if (!id) {
        throw new Error("ID du groupe non fourni.");
      }
  
      const response = await axios.get(`${domain}/groupelignedocuments/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`, 
        },
      });
  
      if (response.status === 200 && response.data?.data) {
  
        commit("SET_GROUPE", response.data.data);
  
        return response.data.data;
      } else {
        throw new Error("Données du groupe introuvables.");
      }
    } catch (error) {
  
      throw new Error(error.response?.data?.message || "Erreur réseau ou serveur.");
    }
  },
  
 
  async addGroupe({ commit }, payload) {
    try {
      const response = await axios.post(`${domain}/groupelignedocuments`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("ADD_GROUPE", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response?.data.message || error.message;
    }
  },

  async updateGroupe({ commit }, payload) {
    try {
      const response = await axios.put(`${domain}/groupelignedocuments/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("UPDATE_GROUPE");
      return response.data.data;
    } catch (error) {
  
      throw error.response?.data.message || error.message;
    }
  },
  async deleteGroupe({ commit }, id) {
    try {
      if (!id) {
        throw new Error("ID du groupe non fourni.");
      }

      // Appel de l'API pour supprimer le groupe
      const response = await axios.delete(`${domain}/groupelignedocuments/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });

      if (response.status === 200) {
        commit("REMOVE_GROUP",response.data);
        return response.data;
      } else {
        throw new Error("Échec de la suppression du groupe.");
      }
    } catch (error) {
      throw error.response?.data.message || error.message;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
