<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }} </div>
            </div>
          </div>
          <form @submit.prevent="chauffe_eau_thermodynamiquesAdd">
            
        
          <b-form-group
            :label="$t('type_installation')"
            label-for="select-type_installation"
          
          >
            <b-form-select
              v-model="chauffe_eau_thermodynamiquesData.type_installation"
              :options="typeInstallationOptions"
              id="select-type_installation"
              class="custom-input"
            >
            </b-form-select>
          </b-form-group>
            <div class="fieldset mb-2" >
              <div class="legend mb-2">
                {{ $t("COP") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
    @paste="(event) => onPaste(event, 'chauffe_eau_thermodynamiquesData.cop')"
                v-model="chauffe_eau_thermodynamiquesData.cop"
                :placeholder="$t('COP')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
          
          </form>
          <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
        </div>
      </div>
    
    </div>
     <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength<=1" @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
      
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div>
    
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <!-- <div v-if="!show"  class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div> -->
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from '@/components/ui/errorSnackbar.vue';
import { preventExceedingLength, handlePaste } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {
  
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default:false,
      required: false,
    },
  
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      errorMessage:'',
      isLoading:false,
      chauffe_eau_thermodynamiquesData: null,
      chauffe_eau_thermodynamiques: false,


      typeInstallationOptions: [
        { value: null, text: this.$t("Sélectionnez_type"),disabled: true },
        { value: 'sur air extrait', text: this.$t("sur air extrait") },
        { value: 'autres types d’installations', text: this.$t("autres types d’installations") },
     
      ],
     
      
    };
  },
  created() {
  if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
    this.chauffe_eau_thermodynamiquesData = this.initializechauffe_eau_thermodynamiques();
  
  }
},
  computed: {
  ...mapState({
    token: (state) => state.token,
  }),
  ...mapGetters(["getdevisMonoFiche"]),
  buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
},

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.chauffe_eau_thermodynamiquesData = this.initializechauffe_eau_thermodynamiques();
          this.chauffe_eau_thermodynamiques = newDevis.data?.devis.chauffe_eau_thermodynamiques !== null;
          if (this.chauffe_eau_thermodynamiques) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
     
  }
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),

    onKeyDown(event) {
      preventExceedingLength(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    async postthermodynamiques(payload) {
     
        try {
          const response = await axios.post(
            domain + `/chauffe_eau_thermodynamiques`,
            payload,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );
         return response.data.data
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "Vérifiez vos données.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
        }
    },
    
    async putthermodynamiques(payload) {
     
     try {
       const response = await axios.put(
         domain + `/chauffe_eau_thermodynamiques/${payload.id}`,
         payload,
         {
           headers: {
             Authorization: `Bearer ` + this.token,
           },
         }
       );
     return response.data.data
     } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "Vérifiez vos données.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
     }
 },
    initializechauffe_eau_thermodynamiques() {
      const chauffe_eau_thermodynamiques = this.getdevisMonoFiche?.data?.devis.chauffe_eau_thermodynamiques || {};
     
      return {
        id: chauffe_eau_thermodynamiques.id,
       
        cop: chauffe_eau_thermodynamiques.cop || "",
        type_installation:chauffe_eau_thermodynamiques.type_installation||"",
      
      };
    },
    async isolationAdd() {
     
      const response = await this.postthermodynamiques(this.chauffe_eau_thermodynamiquesData);
      if(response.id!==null){
      this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques_id = response.id;
      this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques = {
        id: this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques_id,
        cop: this.chauffe_eau_thermodynamiquesData.cop,
        type_installation:this.chauffe_eau_thermodynamiquesData.type_installation,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return response;
    }else{
         
         this.isLoading=false;
 
         return
       }
    },

    async chauffe_eau_thermodynamiquesAdd() {
     if (this.chauffe_eau_thermodynamiques) {
       
        const chauffe = {
          ...this.chauffe_eau_thermodynamiquesData,
          id: this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques.id,
        };
        
        const reponse = await this.putthermodynamiques(chauffe);
        this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques = {
          id: this.getdevisMonoFiche.data.devis.chauffe_eau_thermodynamiques.id,
        
          cop: this.chauffe_eau_thermodynamiquesData.cop,
          type_installation: this.chauffe_eau_thermodynamiquesData.type_installation,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
     
    },
    async goToStep() {
      this.isLoading=true;
      if(!this.chauffe_eau_thermodynamiquesData?.type_installation||!this.chauffe_eau_thermodynamiquesData?.cop){
          this.isLoading=false;
           this.$refs.snackbar.showSnackbar("Tous_obligatoires");
         return;
         }
      await this.chauffe_eau_thermodynamiquesAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;
     return
   }
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },
    async goToNextStep() {
      this.isLoading=true;
      if(!this.chauffe_eau_thermodynamiquesData?.type_installation||!this.chauffe_eau_thermodynamiquesData?.cop){
          this.isLoading=false;
           this.$refs.snackbar.showSnackbar("Tous_obligatoires");
         return;
         }
      await this.chauffe_eau_thermodynamiquesAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
   
     return
   }
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
