<template>
  <div
    class="inner-container"
    :class="{ 'min-container': !getTabs }"
    v-if="getdevisMonoFiche"
  >
    <div class="page-header">
      <h1>{{ $t("CREATION-DEV-MAN") }}</h1>
    </div>
    <form class="bodys">
      <div class="containerz">
        <div class="">
          <div class="row my-2">
            <div
              class="col-lg-6 col-md-6 col-sm-12"
              :class="{
                disabledBlock:
                  this.getdevisMonoFiche.data.devis.entreprise.id !==
                    this.getOnlineUser.entreprise.id && isSuperAdmin,
              }"
            >
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <multiselect
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllentreprises"
                  label="lib"
                  track-by="id"
                  v-model="selectedEntreprise"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>

          <hr v-if="isSuperAdmin" />

          <div
            :class="{
              disabledBlock:
                this.getdevisMonoFiche.data.devis.entreprise.id ===
                  this.getOnlineUser.entreprise.id && isSuperAdmin,
            }"
          >
            <div>
              <b>{{ $t("Généralité") }}</b>
            </div>
            <div class="row my-2">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.visite_technique"
                    class="form-input custom-placeholder custom-input"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_START_DEVIS')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.debut_devis"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_END_DEVIS')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.fin_devis"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('Debut travaux')" class="">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.debut_travaux"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('Fin travaux')">
                  <b-form-input
                    type="date"
                    required
                    v-model="getdevisMonoFiche.data.devis.fin_travaux"
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div class="">
              <div class="">{{ $t("CUSTOMER") }}</div>
              <div class="flex items-center space-x-2">
                <div class="w-full sm:w-1/2 lg:w-750px">
                  <multiselect
                    v-model="selectedClient"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllclients"
                    label="nom"
                    track-by="id"
                    :placeholder="$t('Select_agent')"
                    class="form-input custom-placeholder custom-input"
                    @input="updateClient"
                    style="width: 100%"
                  />
                </div>
                <div>
                  <button
                    class="py-0 newstore rounded"
                    @click="openModal('client')"
                    id="buttonclient-with-popover"
                  >
                    +
                  </button>
                  <b-popover
                    target="buttonclient-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez ajouter un nouveau client") }}
                  </b-popover>
                  <clientmodale @updateList="setup()" />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <b-form-checkbox
                v-model="isSameTravauxAddress"
                @change="handleCheckboxChange"
                :label="$t('QUESTION_ADRESSE')"
                class="custom-input"
              >
                {{ $t("MEME_ADRESSE_TRAVAUX") }}
              </b-form-checkbox>
            </div>
            <div class="row" v-if="isSameTravauxAddress">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_RUE')">
                  <b-form-input
                    :value="getdevisMonoFiche.data.devis.rue_travaux"
                    :placeholder="$t('TRAVAUX_RUE')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_CP')">
                  <b-form-input
                    @paste="
                      (event) =>
                        onPaste(
                          event,
                          'getdevisMonoFiche.data.devis.cp_travaux'
                        )
                    "
                    @keydown="(event) => onKeyDown(event)"
                    @input="validatePostalCode"
                    type="number"
                    maxlength="5"
                    max="99999"
                    min="0"
                    :value="getdevisMonoFiche.data.devis.cp_travaux"
                    :placeholder="$t('TRAVAUX_CP')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_VILLE')">
                  <b-form-input
                    @keydown="preventNumberInput"
                    :value="getdevisMonoFiche.data.devis.ville_travaux"
                    :placeholder="$t('TRAVAUX_VILLE')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_RUE')">
                  <b-form-input
                    v-model="getdevisMonoFiche.data.devis.rue_travaux"
                    :placeholder="$t('TRAVAUX_RUE')"
                    :state="validationStateRue"
                    class="form-input custom-placeholder custom-input"
                    @input="
                      validateRue();
                      updateDevis(
                        'rue_travaux',
                        getdevisMonoFiche.data.devis.rue_travaux
                      );
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validationStateRue">
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_CP')">
                  <b-form-input
                    @paste="
                      (event) =>
                        onPaste(
                          event,
                          'getdevisMonoFiche.data.devis.cp_travaux'
                        )
                    "
                    @keydown="(event) => onKeyDown(event)"
                    @input="validatePostalCode"
                    type="number"
                    maxlength="5"
                    max="99999"
                    required
                    min="0"
                    v-model="getdevisMonoFiche.data.devis.cp_travaux"
                    :placeholder="$t('TRAVAUX_CP')"
                    class="form-input custom-placeholder custom-input"
                    :state="validationState"
                  ></b-form-input>

                  <b-form-invalid-feedback :state="validationState">
                    {{ cpErrorMessage }}
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_VILLE')">
                  <b-form-input
                    v-model="getdevisMonoFiche.data.devis.ville_travaux"
                    :placeholder="$t('TRAVAUX_VILLE')"
                    @keydown="preventNumberInput"
                    :state="validationStateville"
                    class="form-input custom-placeholder custom-input"
                    @input="
                      validateVille();
                      updateDevis(
                        'ville_travaux',
                        getdevisMonoFiche.data.devis.ville_travaux
                      );
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validationStateville">
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div class="flex items-center mb-3">
              <b>{{ $t("Ajoutez une nouvelle section") }}</b>
              <button
                class="ml-2 py-0 px-2 newstore rounded"
                @click="addGroup"
                id="button-with-popover"
              >
                +
              </button>
              <b-popover
                target="button-with-popover"
                placement="top"
                triggers="hover focus"
              >
                {{ $t("Vous pouvez ajouter un nouveau groupe de travaux") }}
              </b-popover>
            </div>
            <!-- <div class="d-flex justify-content-end gap-2">
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Product')"
                >
                  {{ $t("PRODUIT") }}
                </button>
                <articlemodale />
              </div>
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Forfait')"
                >
                  {{ $t("FORFAIT") }}
                </button>
                <forfaitmodale />
              </div>
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Main d\'oeuvre')"
                >
                  {{ $t("MAIN_D_OEUVRE") }}
                </button>
                <mainoeuvremodale />
              </div>
            </div> -->

            <div
              v-for="(group, groupIndex) in getdevisMonoFiche.data
                .groupeLigneDocument || []"
              :key="group.id"
              class=""
            >
              <div class="position">
                <div class="title text-center">
                  <b-form-input
                    v-model="
                      getdevisMonoFiche.data.groupeLigneDocument[groupIndex]
                        .traveaux
                    "
                    :placeholder="$t('nom de group')"
                    maxlength="10"
                    class="custom-input-small pl-4"
                    id="input-with-popover"
                  />
                  <b-popover
                    target="input-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez ajouter un nom à votre groupe") }}
                  </b-popover>
                </div>
              </div>
              <div
                class="d-flex justify-content-center align-items-center flex-column border_pointe scroll-container"
              >
                <div class="col-lg-12 col-sm-12 col-md-10">
                  <div class="checkbox-wrapper">
                    <div class="checkbox-container">
                      <v-checkbox
                        id="input-with-popover"
                        v-model="isChecked[groupIndex]"
                        :label="$t('AJUTER_DESCRIPTION')"
                        @change="handleCheckboxChanges(groupIndex)"
                      ></v-checkbox>
                    </div>
                  </div>

                  <b-popover
                    target="input-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{
                      $t(
                        "Vous pouvez ajouter toutes les descriptions liées à ce groupe de travail"
                      )
                    }}
                  </b-popover>

                  <vue-editor
                    v-if="isChecked[groupIndex]"
                    v-model="group.description"
                    :placeholder="$t('AJUTER_DESCRIPTION')"
                    class="mt-4 vue-editor"
                    :editor-toolbar="customToolbar"
                  />

                  <div class=" ">
                    <div>
                      <div class=" ">
                        <form class="my-4 w-100">
                          <div class="container-wrappe">
                            <div class="d-flex justify-content-end mt-4 px-0">
                              <b-dropdown
                                :text="$t('ADD')"
                                class="button-pre-style mb-2"
                                variant="primary"
                                right
                              >
                                <b-dropdown-item
                                  @click="addRow('Product', groupIndex)"
                                >
                                  {{ $t("PRODUIT") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="addRow('Forfait', groupIndex)"
                                >
                                  {{ $t("FORFAIT") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="addRow('Main d\'oeuvre', groupIndex)"
                                >
                                  {{ $t("MAIN_D_OEUVRE") }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                          <div class="center">
                            <div class="d-flex justify-content-center">
                              <div class="borders">
                                <div class="">
                                  <div class="content-wrapper colorred">
                                    <div>
                                      <div class="header d-flex mb-3 px-3 pt-2">
                                        <div
                                          class="header-item"
                                          style="flex: 2.1"
                                        >
                                          {{ $t("PRODUIT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1"
                                        >
                                          {{ $t("Marque") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1"
                                        >
                                          {{ $t("REF") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 2"
                                        >
                                          {{ $t("WORDING") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 0.7"
                                        >
                                          {{ $t("QTE") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.3"
                                        >
                                          {{ $t("type_uniteU") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.2"
                                        >
                                          {{ $t("PUHT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.4"
                                        >
                                          {{ $t("TOTALHT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="
                                            flex: 0.4;
                                            border-radius: 0 5px 0 0;
                                          "
                                        >
                                          {{ $t("VAT") }}
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        !(
                                          group.products &&
                                          group.products.length
                                        )
                                      "
                                      class="text-center my-4 pb-4"
                                    >
                                      {{ $t("NO_DATA_FOUND") }}
                                    </div>

                                    <div v-else>
                                      <div
                                        v-for="(
                                          row, productIndex
                                        ) in group.products || []"
                                        :key="productIndex"
                                        class="row-item d-flex"
                                      >
                                        <form @submit.prevent="handleSubmit">
                                          <div class="row m-2">
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 2.75"
                                            >
                                              <!-- {{ row.type_ligne }} -->
                                              <div
                                                v-if="row.id"
                                                class="text-container"
                                              >
                                                {{ row.lib }}
                                              </div>
                                              <searchInput
                                                v-if="row.type === 'Product'"
                                                :list="filteredProducts"
                                                label="lib"
                                                :placeholder="$t('PRODUIT')"
                                                :searchFunc="allproducts"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                                :disabled="row.ref !== ''"
                                                class="truncated-label"
                                              />
                                              <searchInput
                                                v-if="row.type === 'Forfait'"
                                                :list="filteredForfaits"
                                                label="lib"
                                                :placeholder="$t('FORFAIT')"
                                                :searchFunc="allForfait"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :disabled="row.ref !== ''"
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                              />
                                              <searchInput
                                                v-if="
                                                  row.type === 'Main d\'oeuvre'
                                                "
                                                :list="filteredMainOeuvres"
                                                label="lib"
                                                :placeholder="
                                                  $t('MAIN_D_OEUVRE')
                                                "
                                                :searchFunc="allmainoeuvres"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :disabled="row.ref !== ''"
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                              />
                                            </div>

                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                v-model="row.fabricant"
                                                :disabled="
                                                  row.type === 'Forfait' ||
                                                  row.type_ligne ===
                                                    'Forfait' ||
                                                  row.type ===
                                                    'Main d\'oeuvre' ||
                                                  row.type_ligne ===
                                                    'Main d\'oeuvre'
                                                "
                                                maxlength="10"
                                                class="custom-input"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                v-model="row.ref"
                                                maxlength="10"
                                                class="custom-input"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper ms-2"
                                              style="flex: 3"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                v-model="row.lib"
                                                @input="
                                                  row.lib = $event.toUpperCase()
                                                "
                                                required
                                                maxlength="100"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                type="number"
                                                @paste="
                                                  (event) =>
                                                    onPaste(
                                                      event,
                                                      'row.quantite'
                                                    )
                                                "
                                                v-model.number="row.quantite"
                                                @keydown="
                                                  (event) => onKeyDown(event)
                                                "
                                                min="1"
                                                required
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-select
                                                class="custom-input"
                                                v-model="row.type_unite"
                                                :options="optionstype"
                                                text-field="text"
                                                value-field="value"
                                                required
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                type="number"
                                                @paste="
                                                  (event) =>
                                                    onPaste(
                                                      event,
                                                      'row.unit_price'
                                                    )
                                                "
                                                v-model.number="row.unit_price"
                                                @keydown="
                                                  (event) => onKeyDown(event)
                                                "
                                                required
                                                step="1"
                                                min="0"
                                                max="10000000.00"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                :value="totalHT(row)"
                                                readonly
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-select
                                                class="custom-input"
                                                v-model.number="row.tva"
                                                :options="optionsTVA"
                                                required
                                              />
                                            </div>
                                          </div>

                                          <div class="row input-wrapper m-2">
                                            <div style="flex: 0.5"></div>
                                            <div class="mx-2" style="flex: 0.5">
                                              {{ $t("DESC") }}
                                            </div>
                                          </div>
                                          <div class="row input-wrapper m-2">
                                            <div style="flex: 2"></div>
                                            <div style="flex: 12">
                                              <vue-editor
                                                v-model="row.desc"
                                                :placeholder="
                                                  $t('AJUTER_DESCRIPTION')
                                                "
                                                class="mt-4"
                                                :editor-toolbar="customToolbar"
                                              />
                                            </div>
                                          </div>
                                          <div class="row m-2">
                                            <div style="flex: 13"></div>
                                            <div style="flex: 1">
                                              <b-button
                                                @click="
                                                  removeRow(step, productIndex)
                                                "
                                                class="button-pre-style custom-input"
                                                id="ligne-with-popover"
                                              >
                                                <b-icon icon="trash"></b-icon>
                                              </b-button>
                                              <b-popover
                                                target="ligne-with-popover"
                                                placement="top"
                                                triggers="hover focus"
                                              >
                                                {{
                                                  $t(
                                                    "Vous pouvez supprimer ce produit"
                                                  )
                                                }}
                                              </b-popover>
                                            </div>
                                          </div>

                                          <hr
                                            v-if="
                                              productIndex <
                                              getdevisMonoFiche.data
                                                .groupeLigneDocument[groupIndex]
                                                ?.products.length -
                                                1
                                            "
                                          />
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div style="flex: 13"></div>
                <div style="flex: 1">
                  <b-button
                    @click="removeGroup(groupIndex, group.id)"
                    class="button-pre-style custom-input"
                    id="group-with-popover"
                  >
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                  <b-popover
                    target="group-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez supprimer ce groupe") }}
                  </b-popover>
                </div>
              </div>
            </div>

            <hr />
            <div>
              <b>{{ $t("PAYMENT") }}</b>
            </div>
            <div class="row mb-4">
              <div class="fieldset col-8">
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>

                <multiselect
                  v-model="selectedMoyensPaiement"
                  :searchable="false"
                  :close-on-select="true"
                  :options="optionspaiement"
                  :multiple="true"
                  :placeholder="$t('sélectionnez_moy')"
                  label="text"
                  track-by="value"
                  required
                  class="form-input custom-placeholder custom-input custom-margin-bottom"
                ></multiselect>
              </div>
            </div>

            <hr />
            <div>
              <b>{{ $t("REMARQUE") }}</b
              >/<b>{{ $t("COND_REG") }}</b>
            </div>
            <vue-editor
              v-model="getdevisMonoFiche.data.devis.remarque"
              :placeholder="$t('REMARQUE')"
              class="mt-4"
              :editor-toolbar="customToolbar"
            />
            <!-- <div class="row">
              <div class="col-lg-6 cool-md-6 col-sm-12">
                <div class="fieldset textarea">
                  <div class="legend">{{ $t("REMARQUE") }}</div>
                  <b-form-textarea
                    id="textarea-large"
                    v-model="getdevisMonoFiche.data.devis.remarque"
                    size="lg"
                    :placeholder="$t('REMARQUE')"
                    maxlength="1000000000000000000000000000000000000000000"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="col-lg-6 cool-md-6 col-sm-12">
                <div class="fieldset">
                  <div class="legend">{{ $t("COND_REG") }}</div>
                  <b-form-textarea
                    v-model="getdevisMonoFiche.data.devis.conditions_reglements"
                    size="lg"
                    :placeholder="$t('COND_REG')"
                    required
                    maxlength="1900000000000000000000000000000000000000"
                  ></b-form-textarea>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-5 submit">
        <b-button
          variant="success"
          @click="updatedevis"
          :title="$t('CHOOSE') + ' ' + $t('PRODUCT')"
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t("GENERATE") }} {{ $t("ESTIMATE") }}
          <div v-if="getdevisLoading" class="ml-2">
            <div class="spinner-border" role="status"></div>
          </div>
        </b-button>
      </div>
    </form>
    <Snackbar ref="snackbar" class="mt-5" />

    <CommentMarche to="/how-it-work/devis"></CommentMarche>
  </div>
</template>

<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import searchInput from "@/components/ui/searchInput";
import CommentMarche from "../components/commentMarche.vue";
import clientmodale from "@/components/Ajoutmodel/clientmodale.vue";
import { preventExceedingLengthFloat, handlePaste } from "@/utils/inputUtils";

export default {
  components: {
    searchInput,
    clientmodale,
    CommentMarche,
    Snackbar,
  },

  data() {
    return {
      isChecked: [],

      columns: [
        {
          label: "PRODUIT",
          model: "product",
          type: "",
          style: { flex: 2 },
          required: true,
        },
        {
          label: "REF",
          model: "ref",
          type: "text",
          style: { flex: 1 },
          required: false,
        },
        {
          label: "WORDING",
          model: "lib",
          type: "text",
          style: { flex: 1 },
          required: false,
        },
        {
          label: "QTE",
          model: "quantite",
          type: "number",
          style: { flex: 1 },
          required: true,
        },
        {
          label: "type_uniteU",
          model: "type_unite",
          type: "select",
          style: { flex: 1 },
          options: [
            { value: "m2", text: this.$t("m2") },
            { value: "m3", text: this.$t("m3") },
            { value: "ml", text: this.$t("ML") },
            { value: "h", text: this.$t("h") },
            { value: "F", text: this.$t("FORFAIT") },
            { value: "U", text: this.$t("unitaire") },
          ],
        },
        {
          label: "PUHT",
          model: "unit_price",
          type: "number",
          style: { flex: 1.5 },
          required: true,
        },
        {
          label: "TOTALHT",
          model: "totalHT",
          type: "number",
          style: { flex: 1 },
          readonly: true,
        },
        {
          label: "VAT",
          model: "tva",
          type: "select",
          style: { flex: 1 },
          options: [
            { value: 0, text: "0%" },
            { value: 5.5, text: "5.5%" },
            { value: 10, text: "10%" },
            { value: 20, text: "20%" },
          ],
        },
      ],
      rows: [],
      isEditorVisible: false,
      content: "",
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // Mise en forme du texte
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // En-têtes h1, h2, h3, h4, h5, h6
        [{ font: [] }], // Sélection de la police
        // [{ size: ["small", false, "large", "huge"] }], // Taille de la police
        [{ color: [] }, { background: [] }], // Couleur de texte et de fond
        [{ align: [] }], // Alignement (gauche, centre, droite, justifié)
        [{ list: "ordered" }, { list: "bullet" }], // Listes ordonnées et à puces
        [{ script: "sub" }, { script: "super" }], // Indices et exposants
        [{ indent: "-1" }, { indent: "+1" }], // Indentation
        ["blockquote", "code-block"], // Bloc de citation et bloc de code
        ["link", "image", "video"], // Lien, image et vidéo
        ["clean"], // Nettoyage de la mise en forme
      ],

      open: false,
      active: false,
      selectedEntreprise: null,
      groupProductIds: null,
      groupes: [],
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "m3", text: this.$t("m3") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "F", text: this.$t("F") },
        { value: "U", text: this.$t("u") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 1, text: "1%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
      ],
      validationStateville: null,
      validationState: null,
      validationStateRue: null,
      cpErrorMessage: "",
      step: 0,
      paiement_state: [],
      isModalOpen: false,
      optionspaiement: [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ],
      selectedMoyensPaiement: [],
      selectedMondataire: null,
      selectedClient: null,
      search: "",
      page: 1,
      per_page: 10,
      loading: false,
    };
  },
  watch: {
    selectedEntreprise: {
      handler() {
        this.updateActiveStatus();
        this.setup();
      },
      immediate: true,
    },
    "getdevisMonoFiche.data.groupeLigneDocument": {
      handler(newGroups) {
        this.isChecked = newGroups.map((group) => group.description !== "");
      },
      deep: true,
    },
    "group.description"(newValue) {
      // Cocher la case si la description n'est pas vide
      this.isChecked = newValue !== "";
    },
    "getdevisMonoFiche.data.devis.client"(newClient) {
      this.selectedClient = newClient;
    },
    "getdevisMonoFiche.data.devis": {
      handler(newDevis) {
        if (newDevis) {
          this.initializeDateData();
          this.setup();

          this.formatDates();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      "updateGroupe",
      "deleteGroupe",
      "fetchOneGroup",
      "addGroupe",
      "deleteProduitOfGroup",
      "createoneProductOfGroup",
      "updateProduct",
      "updatedevisMonoFiche",
      "getoneMonodevis",
      "setDevisMonoFiche",
      "all_mainoeuvres",
      "all_forfaits",
      "all_products",
      "entreprise",
      "all_clients",
      "all_entreprises",
      "allsousTraitant",
      "store_productJSON",
      "store_forfaitjson",
      "store_mainoeuvreJSON",
    ]),

    handleCheckboxChanges(groupIndex) {
      if (!this.isChecked[groupIndex]) {
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";
      } else {
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";
      }
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    toggleEditor(groupIndex) {
      event.preventDefault();
      this.isEditorVisible = !this.isEditorVisible;
      if (
        this.getdevisMonoFiche.data?.groupeLigneDocument[groupIndex]
          ?.description
      ) {
        this.isEditorVisible = false;
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";

        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },
    allForfait(search) {
      this.all_forfaits({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    allproducts(search) {
      this.all_products({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    allmainoeuvres(search) {
      this.all_mainoeuvres({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    addProduits(selectedItem, index) {
      this.addProduit(selectedItem, index);
    },
    setup() {
      this.all_clients({
        page: 1,
        per_page: 1000000,
        entreprise_id: this.getdevisMonoFiche?.data?.devis?.entreprise.id,
      });
    },
    updateClient(client) {
      this.getdevisMonoFiche.data.devis.client = client;
    },
    updateActiveStatus() {
      this.active = this.entrepriseUpdated;
      if (this.entrepriseupdated) {
        this.getdevisMonoFiche.data.devis.entreprise_id =
          this.selectedEntreprise.id;
        this.getdevisMonoFiche.data.devis.entreprise = this.selectedEntreprise;
        this.setup();

        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },
    initializeDateData() {
      const devis = this.getdevisMonoFiche?.data?.devis || {};

      if (!devis.visite_technique) {
        devis.visite_technique = new Date().toISOString().slice(0, 10);
      }
      if (!devis.debut_devis) {
        devis.debut_devis = new Date().toISOString().slice(0, 10);
      }
      if (!devis.fin_devis) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30);
        devis.fin_devis = futureDate.toISOString().slice(0, 10);
      }
      if (!devis.debut_travaux) {
        devis.debut_travaux = new Date().toISOString().slice(0, 10);
      }
      if (!devis.fin_travaux) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 60);
        devis.fin_travaux = futureDate.toISOString().slice(0, 10);
      }
    },
    async addGroup() {
      event.preventDefault();
      const groupProduit = {
        document_id: this.getdevisMonoFiche.data.id,
        type_group: "Libre",
      };

      try {
        const newGroup = await this.addGroupe(groupProduit);

        if (newGroup) {
          const groupProductes = await this.fetchOneGroup(newGroup.id);
          this.$refs.snackbar.showSnackbar("Le groupe est bien ajouté");
          if (!this.getdevisMonoFiche.data.groupeLigneDocument) {
            this.$set(this.getdevisMonoFiche.data, "groupeLigneDocument", []);
          }

          this.getdevisMonoFiche.data.groupeLigneDocument.push(groupProductes);

          this.setDevisMonoFiche(this.getdevisMonoFiche);
          this.step++;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    formatDateLocal(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    formatDates() {
      const devis = this.getdevisMonoFiche?.data?.devis;
      if (devis) {
        devis.visite_technique = this.formatDateLocal(devis.visite_technique);
        devis.debut_devis = this.formatDateLocal(devis.debut_devis);
        devis.fin_devis = this.formatDateLocal(devis.fin_devis);
        devis.debut_travaux = this.formatDateLocal(devis.debut_travaux);
        devis.fin_travaux = this.formatDateLocal(devis.fin_travaux);
      }
    },
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    validatePostalCode() {
      const cpValue = this.getdevisMonoFiche.data.devis.cp_travaux;

      if (
        cpValue.length >= 4 &&
        cpValue.length <= 5 &&
        /^[0-9]{4,5}$/.test(cpValue)
      ) {
        this.validationState = true;
        this.cpErrorMessage = "";
      } else {
        this.validationState = false;
        this.cpErrorMessage =
          "Please enter a valid postal code (4 to 5 digits).";
      }
    },
    preventNumberInput(event) {
      const key = event.key;

      if (key >= "0" && key <= "9") {
        event.preventDefault();
      }
    },
    validateRue() {
      const rueValue = this.getdevisMonoFiche.data.devis.rue_travaux;

      if (rueValue.trim() !== "") {
        this.validationStateRue = true;
      } else {
        this.validationStateRue = false;
      }
    },
    validateVille() {
      const villeValue = this.getdevisMonoFiche.data.devis.ville_travaux;

      if (villeValue.trim() !== "") {
        this.validationStateville = true;
      } else {
        this.validationStateville = false;
      }
    },
    async getPaiement(devis_ID) {
      try {
        const response = await axios.get(domain + `/devis_moyens_paiement`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            devis_id: devis_ID,
          },
        });

        return response.data.data;
      } catch (error) {
        throw new Error(
          error.response?.data?.message ||
            "An error occurred while fetching the payment information."
        );
      }
    },
    async postPaiement(payload) {
      const responses = [];
      for (const item of payload) {
        try {
          const dataToSend = {
            devis_id: this.getdevisMonoFiche.data.devis.id,
            moyen_paiement: item,
          };

          const response = await axios.post(
            domain + `/devis_moyens_paiement`,
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );

          responses.push(response);
        } catch (error) {
          throw new Error("Failed to post payment.");
        }
      }
      return responses;
    },
    async deletePaiement(ids) {
      const responses = [];

      for (const id of ids) {
        try {
          const response = await axios.delete(
            domain + `/devis_moyens_paiement/${id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
          responses.push(response.data);
        } catch (error) {
          responses.push({
            id,
            error:
              error.response?.data?.message ||
              "An error occurred while deleting the payment.",
          });
        }
      }

      return responses;
    },
    paiement() {
      if (!this.paiement_state || !Array.isArray(this.paiement_state)) {
        return;
      }

      const selectedValues =
        this.selectedMoyensPaiement?.map((option) => option.value) || [];

      const idsExistants = selectedValues.filter(
        (moyen_paiement) =>
          !this.paiement_state.some(
            (obj) => obj.moyen_paiement === moyen_paiement
          )
      );

      if (idsExistants) {
        this.postPaiement(idsExistants);
      }

      const idsNonExistants = this.paiement_state
        .filter(
          (obj) =>
            !this.selectedMoyensPaiement.some(
              (moyen) => moyen.value === obj.moyen_paiement
            )
        )
        .map((obj) => obj.id);

      if (idsNonExistants) {
        this.deletePaiement(idsNonExistants);
      }
    },
    openModal(type) {
      event.preventDefault();
      if (type === "client") {
        this.$root.$emit("bv::show::modal", "clientStoreModal");
      }
      if (type === "Product") {
        this.$root.$emit("bv::show::modal", "ProductModal");
        this.open = true;
      }
      if (type === "Forfait") {
        this.$root.$emit("bv::show::modal", "forfaitModal");
      }
      if (type === "Main d'oeuvre") {
        this.$root.$emit("bv::show::modal", "mainoeuvreModal");
      }
    },
    async removeRow(step, index) {
      const rowToRemove =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          ?.id;

      if (!rowToRemove) {
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      if (rowToRemove) {
        await this.deleteProduitOfGroup(rowToRemove);
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      this.addedProducts = this.addedProducts.filter(
        (item) => !(item.step === step && item.index === index)
      );

      this.addedProducts = this.addedProducts.map((item) => {
        if (item.step === step && item.index > index) {
          return { step: item.step, index: item.index - 1 };
        }
        return item;
      });
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    handleRemoveRow(index) {
      this.removeRow(this.step, index);
    },

    async removeGroup(index, id) {
      await this.deleteGroupe(id);
      this.getdevisMonoFiche.data.groupeLigneDocument.splice(index, 1);
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },

    addProduit(selectedItem, index, groupIndex) {
      const group = this.getdevisMonoFiche.data.groupeLigneDocument[groupIndex];
      const productType = group?.products[index]?.type;

      let updatedProduct = null;

      if (productType === "Product") {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: product.ref,
          lib: product.lib,
          fabricant: product.fabricant,
          type_ligne: product.type_ligne,
          unit_price: product.unit_price,
          type_unite: product.type_unite,
          tva: product.tva,
          desc: product.desc,
          searchInputValue: product,
          produit_id: product.id,
          totalHT: product.unit_price * group?.products[index].quantite,
        };
      } else if (productType === "Forfait") {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: forfait.ref,
          lib: forfait.lib,
          unit_price: forfait.unit_price,
          type_unite: forfait.type_unite,
          tva: forfait.tva,
          type_ligne: forfait.type_ligne,
          desc: forfait.desc,
          searchInputValue: forfait,
          produit_id: forfait.id,
          totalHT: forfait.unit_price * group?.products[index].quantite,
        };
      } else if (productType === "Main d'oeuvre") {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: mainoeuvre.ref,
          lib: mainoeuvre.lib,
          desc: mainoeuvre.desc,
          unit_price: mainoeuvre.unit_price,
          type_ligne: mainoeuvre.type_ligne,
          type_unite: mainoeuvre.type_unite,
          tva: mainoeuvre.tva,
          searchInputValue: mainoeuvre,
          produit_id: mainoeuvre.id,
          totalHT: mainoeuvre.unit_price * group?.products[index].quantite,
        };
      }

      if (updatedProduct) {
        this.$set(group.products, index, updatedProduct);
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },

    addRow(type, groupIndex) {
      const group = this.getdevisMonoFiche.data.groupeLigneDocument[groupIndex];
      const createRow = (rowType) => ({
        groupelignedocument_id: group.id,
        type: rowType,
        ref: "",
        lib: "",
        fabricant: "",
        quantite: 1,
        type_unite: null,
        unit_price: 1,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });

      group.products.push(createRow(type));
    },

    handleCheckboxChange() {
      if (this.isSameTravauxAddress) {
        this.getdevisMonoFiche.data.devis.rue_travaux =
          this.getdevisMonoFiche.data.devis.client.rue || "";
        this.getdevisMonoFiche.data.devis.cp_travaux =
          this.getdevisMonoFiche.data.devis.client.cp || "";
        this.getdevisMonoFiche.data.devis.ville_travaux =
          this.getdevisMonoFiche.data.devis.client.ville || "";
      } else {
        this.updateDevis(
          "rue_travaux",
          this.getdevisMonoFiche.data.devis.rue_travaux
        );
        this.updateDevis(
          "cp_travaux",
          this.getdevisMonoFiche.data.devis.cp_travaux
        );
        this.updateDevis(
          "ville_travaux",
          this.getdevisMonoFiche.data.devis.ville_travaux
        );
      }
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    async handleRouteChange() {
      const id = this.$route.params.id;
      if (id) {
        await this.fetchDevisObject(id);
      }
    },

    async fetchDevisObject(id) {
      const fetchedDevisObject = await this.getoneMonodevis(id);
      fetchedDevisObject.data.devis.brouillon = 1;
      await this.setDevisMonoFiche(fetchedDevisObject);
    },
    async valideDevis(getdevisMonoFiche) {
      const { groupeLigneDocument, devis } = getdevisMonoFiche.data;

      if (
        this.selectedMoyensPaiement.length > 0 &&
        Array.isArray(groupeLigneDocument) &&
        groupeLigneDocument[0].products.length > 0 &&
        devis.client &&
        devis.debut_devis &&
        devis.fin_devis &&
        devis.cp_travaux &&
        devis.visite_technique &&
        devis.rue_travaux &&
        devis.ville_travaux
      ) {
        devis.brouillon = 0;
        getdevisMonoFiche.data.brouillon = 0;
      } else {
        devis.brouillon = 1;
        getdevisMonoFiche.data.brouillon = 1;
      }

      await this.setDevisMonoFiche(getdevisMonoFiche);
    },

    async updatedevis() {
      try {
        this.getdevisMonoFiche.data.devis.client_id = this.selectedClient?.id;
        if (this.isSuperAdmin) {
          this.getdevisMonoFiche.data.devis.entreprise_id =
            this.selectedEntreprise?.id;
        }

        this.paiement();

        for (const group of this.getdevisMonoFiche.data.groupeLigneDocument) {
          const newData = {
            id: group.id,
            // type_group: group.type_group,
            traveaux: group.traveaux,
            description: group.description,
          };

          this.updateGroupe(newData);
          for (const [
            groupIndex,
            group,
          ] of this.getdevisMonoFiche.data.groupeLigneDocument.entries()) {
            const newData = {
              id: group.id,
              traveaux: group.traveaux,
              description: group.description,
            };

            this.updateGroupe(newData);

            for (const [productIndex, product] of group.products.entries()) {
              if (product) {
                if (product.id) {
                  await this.updateProduct(product);
                  continue;
                }

                const groupelignedocumentId = String(
                  product.groupelignedocument_id
                );

                if (!product.produit_id) {
                  if (product.type === "Forfait") {
                    const res = await this.store_forfaitjson(product);
                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le forfait ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le forfait ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }
                    product.produit_id = res;
                  }

                  if (product.type === "Main d'oeuvre") {
                    const res = await this.store_mainoeuvreJSON(product);
                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le main d'oeuvre ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le main d'oeuvre ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }
                    product.produit_id = res;
                  }
                  if (product.type === "Product") {
                    const res = await this.store_productJSON(product);

                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le produit ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le produit ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }

                    product.produit_id = res;
                  }
                }

                if (!product.produit_id) {
                  this.$refs.snackbar.showSnackbar(
                    `Erreur: Produit ID manquant à l'index ${productIndex} du groupe ${groupIndex}`
                  );
                  return;
                }

                const produitId = String(product.produit_id);

                await this.createoneProductOfGroup({
                  ...product,
                  groupelignedocument_id: groupelignedocumentId,
                  produit_id: produitId,
                  type_ligne: product.type,
                });
              }
            }
          }
        }
        const debutDevis = new Date(
          this.getdevisMonoFiche?.data?.devis?.debut_devis
        );
        const finDevis = new Date(
          this.getdevisMonoFiche?.data?.devis?.fin_devis
        );
        const visiteTechnique = new Date(
          this.getdevisMonoFiche?.data?.devis?.visite_technique
        );
        if (visiteTechnique > debutDevis) {
          this.$refs.snackbar.showSnackbar("error_visite_debut_devis");
          this.isLoading = false;
          return;
        }
        if (debutDevis > finDevis) {
          this.$refs.snackbar.showSnackbar("error_debut_fin_devis");
          return;
        }
        const debutTravaux = new Date(
          this.getdevisMonoFiche?.data?.devis?.debut_travaux
        );
        const finTravaux = new Date(
          this.getdevisMonoFiche?.data?.devis?.fin_travaux
        );

        if (debutTravaux < debutDevis) {
          this.$refs.snackbar.showSnackbar(`error_date_devis`);

          return;
        }
        if (debutTravaux > finTravaux) {
          this.$refs.snackbar.showSnackbar(`error_date_travaux`);

          return;
        }

        this.valideDevis(this.getdevisMonoFiche);
        this.setDevisMonoFiche(this.getdevisMonoFiche);

        const reponse = await this.updatedevisMonoFiche(
          this.getdevisMonoFiche.data.devis
        );
        this.$router.push("/devis/list");
        return reponse;
      } catch (error) {
        throw new Error(error);
      }
    },
    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },
  },

  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters([
      "getGroupe",
      "getMondataireEntitesExternes",
      "getdevisMonoFiche",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getforfaitLoading",
      "getTabs",
      "getAllProducts",
      "getAllclients",
      "getOnlineUser",
      "getdevis",
      "getAllentreprises",
      "getdevisLoading",
    ]),
    initializeIsChecked() {
      return this.getdevisMonoFiche.data?.groupeLigneDocument.map(
        (group) => group.description !== ""
      );
    },
    isEditorVisibles() {
      return this.isChecked || this.group.description !== "";
    },
    filteredProducts() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Product")
        .map((product) => product.produit_id);

      return this.getAllProducts.filter(
        (product) => !selectedIds.includes(product.id)
      );
    },
    filteredForfaits() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Forfait")
        .map((product) => product.produit_id);

      return this.getAllforfaits.filter(
        (forfait) => !selectedIds.includes(forfait.id)
      );
    },
    filteredMainOeuvres() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Main d'oeuvre")
        .map((product) => product.produit_id);

      return this.getAllmainoeuvres.filter(
        (mainoeuvre) => !selectedIds.includes(mainoeuvre.id)
      );
    },
    isBlockEnabled() {
      return this.active;
    },
    entrepriseupdated() {
      if (this.isSuperAdmin) {
        return (
          this.selectedEntreprise &&
          this.selectedEntreprise.id !== this.getOnlineUser.entreprise.id
        );
      }
      return false;
    },
    isSameTravauxAddress: {
      get() {
        return this.getdevisMonoFiche.data.devis.same_travaux_address === 1;
      },
      set(value) {
        this.getdevisMonoFiche.data.devis.same_travaux_address = value ? 1 : 0;
        this.handleCheckboxChange();
      },
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
  },

  async created() {
    this.handleRouteChange();

    if (this.getdevisMonoFiche?.data?.devis) {
      this.initializeDateData();
      this.formatDates();
    }
    const devi_ID = this.$route.params.id;
    try {
      const data = await this.getPaiement(devi_ID);
      this.paiement_state = data;

      const moyensPaiement = Array.from(
        new Set(this.paiement_state.map((item) => item.moyen_paiement))
      );

      this.selectedMoyensPaiement = moyensPaiement
        .map((moyen) => {
          const option = this.optionspaiement.find(
            (option) => option.value === moyen
          );
          return option ? { value: option.value, text: option.text } : null;
        })
        .filter((moyen) => moyen !== null);
    } catch (error) {
      throw new Error(error);
    }
  },
  mounted() {
    this.all_mainoeuvres({ page: this.page, per_page: 100000 });
    this.all_products({ page: this.page, per_page: 100000 });
    this.all_forfaits({ page: this.page, per_page: 100000 });
    this.setup();
    this.isChecked = this.initializeIsChecked;
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-width: 1000px;
  position: relative;
  padding: 1rem;
}

.checkbox-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.ql-snow * {
  all: unset;
}
.container-wrappe {
  min-width: 1000px;
  display: flex;
  justify-content: flex-end;
}

.d-flex {
  width: 100%;
}

.vue-editor {
  min-width: 1000px;
  max-width: 100%;
  overflow-x: auto;
  white-space: normal;
}

.quillWrapper {
  background-color: white !important;
}

.colorred {
  background-color: #f8f9fa;
}
.text-container {
  max-width: 16ch;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.bodys {
  margin: 0px 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 88vh;
}
.flex {
  display: flex;
  align-items: center;
}

.space-x-2 > *:not(:last-child) {
  margin-right: 8px;
}

.newstore {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new {
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24316d;
  color: white;
}

.marg {
  margin-top: 4.5%;
}

.position {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.05rem 2rem;
  width: 100%;
  height: 100%;
}

.custom-input-small {
  width: 150px;
  height: 30px;
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  box-shadow: none !important;
  position: absolute;
  background-color: #fff;
}

.disabledBlock {
  opacity: 0.5;
  pointer-events: none;
  margin-bottom: 50px;
}
.page-header {
  h1 {
    width: 100%;
  }
}

.body {
  @media only screen and (max-width: 900px) {
    padding: 8px;
    width: 100%;
  }
  width: 97%;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 5px;

  .containerz {
    @media only screen and (max-width: 900px) {
      padding: 8px;
      width: 100%;
      margin: 0;
    }
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 6px 0px rgba(229, 229, 229, 0.75);
    padding: 42px;
  }
}
.tabs-container {
  display: flex;
  justify-content: center;
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  color: white;
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}

.custom-input {
  box-shadow: none !important;
  width: 100%;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.borders {
  display: block;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.scroll-container {
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
.border_pointe {
  margin: 10px;
  border: 2px dashed #24316d;
  position: static;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
</style>
